export const SpectrumCards: [string, string][] = [
  ['Old', 'New'],
  ['Mild', 'Spicy'],
  ['Skill', 'Luck'],
  ['Nature', 'Nurture'],
  ['For kids', 'For adults'],
  ['Need', 'Want'],
  ['Deserted', 'Crowded'],
  ['Safe', 'Dangerous'],
  ['Sport', 'Game'],
  ['Flashy', 'Modest'],
  ['Formal', 'Casual'],
  ['Dog name', 'Cat name']
  // ["Luck", "Skill"],
  // ["Quiet", "Loud"],
  // ["Hot", "Cold"],
  // ["For kids", "For adults"],
  // ["Low calorie", "High calorie"],
  // ["Plain", "Fancy"],
  // ["Smells bad", "Smells good"],
  // ["Old", "New"],
  //["local", "global"]
  // ["Plain", "Fancy"],
  // ["Better hot", "Better cold"],
  // ["Colorless", "Colorful"],
  // ["Cheap", "Expensive"],
  //["Funny", "Serious"],
// ["Mild", "Spicy"]
// ["Unnatural", "Natural"]
// ["Casual", "Formal"]
  
];

// const AdvancedSpectrumCards: [string, string][] = [
//   ["Short", "Long"],
//   ["Worst year in history", "Best year in history"],
//   ["Famous", "Infamous"],
//   ["Least powerful god", "Most powerful god"],
//   ["Unsexy color", "Sexy color"],
//   ["Benefits you", "Benefits everyone"],
//   ["Bad president", "Good president"],
//   ["Weird", "Strange"],
//   ["Derivative", "Original"],
//   ["Etiquette", "Manners"],
//   ["The worst", "The best"],
//   ["Small number", "Large number"],
//   ["Not enough", "Too much"],
//   ["Hard to sit on", "Easy to sit on"],
//   ["Talent", "Skill"],
//   ["Worst era to time travel", "Best era to time travel"],
//   ["Not huggable", "Huggable"],
//   ["Heterogeneous", "Homogeneous"],
//   ["Out of control", "In control"],
//   ["Popular", "Elitist"],
//   ["Non-partisan", "Partisan"],
//   ["Dog name", "Cat name"],
//   ["Little known fact", "Well known fact"],
//   ["Socialist", "Capitalist"],
//   ["Bad candy", "Good candy"],
//   ["Traditional", "Radical"],
//   ["Bad mouthfeel", "Good mouthfeel"],
//   ["Illegal", "Legal"],
//   ["Never on time", "Always on time"],
//   ["Won't live to 100", "Will live to 100"],
//   ["Bad Disney character", "Good Disney character"],
//   ["Similar", "Identical"],
//   ["Limp", "Firm"],
//   ["Funny topic", "Serious topic"],
//   ["Unscented", "Scented"],
//   ["Horizontal", "Vertical"],
//   ["Small", "Tiny"],
//   ["Ugly word", "Beautiful word"],
//   ["Tick", "Tock"],
//   ["Bad advice", "Good advice"],
//   ["Illegal", "Prohibited"],
//   ["Not art", "Art"],
//   ["Gossip", "News"],
//   ["Guilty pleasure", "Actually just bad"],
//   ["Old fashioned", "Avant garde"],
//   ["True", "False"],
//   ["Normal greeting", "Weird greeting"],
//   ["Dictatorship", "Democracy"],
//   ["Powerless", "Powerful"],
//   ["Vapes", "Doesn't Vape"],
//   ["Boring person", "Fun person"],
//   ["Underrated book", "Overrated book"],
//   ["Deep thought", "Shallow thought"],
//   ["Bad school", "Good school"],
//   ["Conventional wisdom", "Fringe belief"],
//   ["Worst chore", "Best chore"],
//   ["Endangered species", "Overpopulated species"],
//   ["Blue", "Green"],
//   ["Fruit", "Vegetable"],
//   ["Science", "Pseudoscience"],
//   ["Small talk", "Heavy topic"],
//   ["Bad investment", "Good investment"],
//   ["Stationary", "Mobile"],
//   ["Local issue", "Global issue"],
//   ["Thrilling", "Terrifying"],
//   ["Nerd", "Jock"],
//   ["Expected", "Unexpected"],
//   ["Person you could beat up", "Person who'd beat you up"],
//   ["Limited", "Infinite"],
//   ["Casual event", "Formal event"],
//   ["Unreasonable phobia", "Reasonable phobia"],
//   ["Underrated game", "Overrated game"],
//   ["Religious", "Sacrilegious"],
//   ["Mild", "Spicy"],
//   ["Genuine person", "Phony person"],
//   ["Unnatural", "Natural"],
//   ["Secret", "Public Knowledge"],
//   ["Too small", "Too big"],
//   ["Art", "Commerce"],
//   ["One hit wonder", "Pop icon"],
//   ["Unsexy Pokémon", "Sexy Pokémon"],
//   ["Quiet", "Loud"],
//   ["Inclusive", "Exclusive"],
//   ["Bad dog (breed)", "Good dog (breed)"],
// ];

export const AllCards = [...SpectrumCards];


