import { useRef, useContext, useState, useEffect} from "react";
import Tippy from "@tippyjs/react";
import { GamePhase, RoundPhase } from "../../state/GameState";
import { Spectrum } from "../common/Spectrum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GameModelContext } from "../../state/GameModelContext";
import {
  Center,
  CircularProgress,
  Grid,
  GridItem,
  Heading,
  Text,
  Input,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ForfeitModal, PartnerCheckModal, SurveyModal, PassModal} from "../common/Modals";
import { Timer } from "../common/Timer";

function ErrorMessage(props: {
  errorVal:any
}) {
  if (props.errorVal.noNumberError) {
    return (
      <Text
        fontFamily="Reem Kufi"
        color="red"
      >
        No numbers allowed in the clue.
      </Text>
    );
  } 
  else if (props.errorVal.moreThanTwoError) {
    return (
      <Text
        fontFamily="Reem Kufi"
        color="red"
      >
        Only clues of one or two words allowed.
      </Text>
    );
  } 
  else if (props.errorVal.cardError) {
    return (
      <Text
        fontFamily="Reem Kufi"
        color="red"
      >
        Clues can't contain the scale words themselves.
      </Text>
    );
  } 
  else if (props.errorVal.moreThan20CharError) {
    return (
      <Text
        fontFamily="Reem Kufi"
        color="red"
      >
        Clue is too long. Up to 20 characters allowed.
      </Text>
    );
  } 
  else if (props.errorVal.directionError) {
    return (
      <Text
        fontFamily="Reem Kufi"
        color="red"
      >
        '{props.errorVal.direction}' present in blacklist. Please try a different clue.
      </Text>
    );
  } 
  else if (props.errorVal.alphaError) {
    return (
      <Text
        fontFamily="Reem Kufi"
        color="red"
      >
        Only letters and whitespace allowed.
      </Text>
    );
  }
  else{ 
    return <div>{}</div>
  }
}


export function GiveClue() {

  const {
    gameState,
    localPlayer,
    clueGiver,
    spectrumCard,
    QuestionCard,
    setGameState,
  } = useContext(GameModelContext);

  var expiryTimestamp = new Date();
	const [widthLargerThan1300] = useMediaQuery('(min-width: 1300px)');
	const [heightLargerThan600] = useMediaQuery('(min-height: 600px)');

  const inputElement = useRef<HTMLInputElement>(null);
  const [disableSubmit, setDisableSubmit] = useState(
    !inputElement.current?.value?.length
  );
  
  const partner_name = JSON.parse(localStorage.getItem("partner_name")||"{}")
  // Error checking for clue.

  const [errorVal, setError] = useState({moreThanTwoError:false, moreThan20CharError:false,
                                      directionError:false, cardError:false,
                                      noNumberError:false, alphaError:false,
                                      direction:""});
  
  const [visible, setVisible] = useState(false);
  const [passMsg, setPassMsg] = useState(false);
  const [forfeitMsg, setForfeitMsg] = useState(false);


  useEffect(()=>{


    const clue_start_time = Math.round(Date.now() / 1000)
    localStorage.setItem("clue_start_time", JSON.stringify(clue_start_time))

    var trial_time = 0
    if(gameState.gamePhase === GamePhase.Trial){
      setVisible(true)
      trial_time += 10000
    }

    const passmsg_timeout_id = setTimeout(()=>{setPassMsg(true)},121000)
    const forfeitmsg_timeout_id = setTimeout(()=>{setForfeitMsg(true)},155000 + trial_time)

    return() =>{
      clearTimeout(passmsg_timeout_id)
      clearTimeout(forfeitmsg_timeout_id)
    }
    
  },[])

  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  
  const handleClue = (clue: string | undefined) => {
    if (!clue) {
      return false;
    }
    if (!clue.match(/^[a-zA-Z ]+$/)) {
      setError({moreThanTwoError:false,
        moreThan20CharError:false,
        directionError:false,
        cardError:false,
        noNumberError:false,
        alphaError:true,
        direction:""
      })
      return false;
    }
    if (clue.match(/\d+/)) {
      setError({moreThanTwoError:false,
        moreThan20CharError:false,
        directionError:false,
        cardError:false,
        noNumberError:true,
        alphaError:false,
        direction:""
      })
      return false;
    }

    if (clue.match(/\b(one|two|three|four|five|six|seven|eight|nine|ten|hundred|centum|dozen|zero|eleven|quarter|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|(zero|one|two|three|four|five|six|seven|eight|nine)(zero|one|two|three|four|five|six|seven|eight|nine))\b/i)) {
      setError({moreThanTwoError:false,
        moreThan20CharError:false,
        directionError:false,
        cardError:false,
        noNumberError:true,
        alphaError:false,
        direction:""
      })
      return false;
    }
        
    const card_pattern = new RegExp("\\b(" + spectrumCard[0].split(" ").join("|") + "|" + spectrumCard[1].split(" ").join("|") + ")\\b", "i")

    if (clue.match(card_pattern)) {
      setError({moreThanTwoError:false,
        moreThan20CharError:false,
        directionError:false,
        cardError:true,
        noNumberError:false,
        alphaError:false,
        direction:""
      })
      return false;
    }

    let clueArr = clue.split(/\s+/);
    if (clueArr.length > 2) {
      setError({moreThanTwoError:true,
        moreThan20CharError:false,
        directionError:false,
        cardError:false,
        noNumberError:false,
        alphaError:false,
        direction:""
      })
      return false;
    }
    

    if (clue.length > 20) {
      setError({moreThanTwoError:false,
        moreThan20CharError:true,
        directionError:false,
        cardError:false,
        noNumberError:false,
        alphaError:false,
        direction:""
      })
      return false;
    }

    var direction_match = clue.match(/\b(left|right|close|few|fewer|between|very|many|midway|mid|same|higher|lower|half|high|low|medium|more|less|middle|correct|above|below|lot|little)\b/i)
    if (direction_match){
      setError({moreThanTwoError:false,
        moreThan20CharError:false,
        directionError:true,
        cardError:false,
        noNumberError:false,
        alphaError:false,
        direction: direction_match[0]
      })
      return false;
    }

    setGameState({
      clue: clue,
      roundPhase: RoundPhase.MakeGuess,
    });
  }

  if (!clueGiver) {
    setGameState({
      clueGiver: localPlayer.id,
    });
    return null;
  }



  var headingSize = widthLargerThan1300 && heightLargerThan600 ? "2.0rem" : "1.5rem";
  var fontSize = widthLargerThan1300 && heightLargerThan600 ? "1.5rem" : "1.2rem";

  if (localPlayer.id !== clueGiver.id) {
    return (
      <Grid
        height={{ base: '50vh', lg: '70vh' }}
        width={{ base: '80vw', lg: '60vw' }}
        templateRows="repeat(11, 1fr)"
        templateColumns="repeat(11, 1fr)"
      >
        {forfeitMsg && <SurveyModal/>}
        {passMsg && !forfeitMsg && <PartnerCheckModal/>}
        <GridItem colSpan={11} rowStart={1} rowSpan={1}>
          <Heading mb={2} pl={15} pr={15} w="full" fontSize={headingSize} fontFamily="Reem Kufi" fontWeight="normal">
            {QuestionCard[0]}
          </Heading>
          {(gameState.gamePhase === GamePhase.Trial) &&<Text pl={15} pr={15} textColor="#858585" fontSize={fontSize} fontFamily="Reem Kufi">
            Tip: Use this time to think of possible clues that {clueGiver.name} might come up with.
          </Text>}
        </GridItem>
        <GridItem colSpan={11} rowStart={4}>
          <Spectrum spectrumCard={spectrumCard} initialGuessValue={gameState.guesser_initial_guess} clueGiverGuessValue={gameState.cluegiver_initial_guess} clueGiverName={clueGiver.name} roundPhase={gameState.roundPhase} />
        </GridItem>
        <GridItem colSpan={11} rowStart={7}>
          <Center>
            <Text textAlign={"center"} fontSize={fontSize} fontFamily="Reem Kufi" fontWeight="normal">
              {clueGiver.name} is coming up with a clue...
            </Text>
          </Center>
        </GridItem>
        <GridItem colSpan={11} rowStart={8}>
        <Center>
          <Text textAlign={"center"} fontSize={fontSize} fontFamily="Reem Kufi" color="black">
          {/* <Timer initialMinute={2} initialSeconds={0} /> */}
          <Timer expiryTimestamp={expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 120)} />
        </Text>
        </Center>
        </GridItem>
        <GridItem colSpan={11} rowStart={9}>
          <Center>
            <CircularProgress size="3vw" isIndeterminate color='purple.300' />
          </Center>
        </GridItem>
      
      </Grid>
    );
  }

  
    const info_html = <div>
  {"Your clue should be some concept located where the target is between the two extremes. Your clue should be:"}
  <ul>
    <li>{"One or two words only"}</li>
    <li>{"Less than 20 characters"}</li>
    <li>{"No numbers allowed"}</li>
  </ul>
  {"Be creative! Good luck!"}
</div>
  
  return (
    <Grid
      height={{ base: '50vh', lg: '70vh' }}
      width={{ base: '80vw', lg: '60vw' }}
      templateRows="repeat(11, 1fr)"
      templateColumns="repeat(11, 1fr)"
    >

        {forfeitMsg && <ForfeitModal/>}
        {passMsg && !forfeitMsg && <PassModal/>}
        
      <GridItem colSpan={11} rowSpan={1}>
        <Heading mb={2} pl={15} pr={15} w="full"  fontSize={headingSize} fontFamily="Reem Kufi" fontWeight="normal">
          {QuestionCard[0]}
        </Heading>
        {(gameState.gamePhase === GamePhase.Trial) &&<Text pl={15} pr={15} textColor="#858585" fontSize={fontSize} fontFamily="Reem Kufi">
          Give your best clue and be creative!
        </Text>}
      </GridItem>
      <GridItem colSpan={11} rowStart={4}>
        <Spectrum
          targetValue={QuestionCard[1]}//{gameState.spectrumTarget}
          spectrumCard={spectrumCard}
          roundPhase={gameState.roundPhase}
          initialGuessValue={gameState.cluegiver_initial_guess} 
          clueGiverGuessValue={gameState.guesser_initial_guess} 
          clueGiverName={partner_name}
        />
      </GridItem>
      <GridItem colStart={7} rowStart={8}>
      <Tippy content={info_html} visible={visible} onClickOutside={hide} placement="bottom">
        <div style={{ margin: 8 }}>
          <FontAwesomeIcon onClick={visible ? hide : show} icon={faInfoCircle} />
        </div>
      </Tippy>
        </GridItem>
      {!passMsg && !forfeitMsg && <GridItem colStart={6} rowStart={8}>
      <Input
          autoFocus={true}
          borderWidth={0}
          minWidth='10vw'
          variant='flushed'
          fontSize='1.5rem'
          fontFamily='Reem Kufi'
          placeholder={"Enter clue"}
          ref={inputElement}
          onKeyDown={(event) => {
            if (event.key !== "Enter") {
              return true;
            }
            handleClue(inputElement.current?.value);
          }}
          onChange={() => {
            setDisableSubmit(!inputElement.current?.value?.length);
          }}
        />
        <ErrorMessage
          errorVal={errorVal}
        />
        <Center>
          <Button
            mt={4}
            fontSize={widthLargerThan1300 && heightLargerThan600 ? '1.5rem' : '1.3rem'}
            size={widthLargerThan1300 && heightLargerThan600 ? 'lg' : 'sm'}
            bg="#65C863"
            onClick={() => {
              handleClue(inputElement.current?.value);
            }}
            disabled={disableSubmit}
          >
            Submit
          </Button>
          
        </Center>
      </GridItem>}
      {passMsg && !forfeitMsg && <GridItem colStart={6} rowStart={8}>
      
        <Center>
          <Button
            mt={4}
            fontSize={widthLargerThan1300 && heightLargerThan600 ? '1.5rem' : '1.3rem'}
            size={widthLargerThan1300 && heightLargerThan600 ? 'lg' : 'sm'}
            bg="#D7A1F9"
            onClick={() => {
              handleClue("PASS");
            }}
          >
            PASS
          </Button>
          
        </Center>
      </GridItem>}
      <GridItem colSpan={11} rowStart={9}>
      <Text textAlign={"center"} fontSize={fontSize} fontFamily="Reem Kufi" color="black">
        {/* <Timer initialMinute={2} initialSeconds={0} /> */}
        <Timer expiryTimestamp={expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 120)} />
        </Text>
     </GridItem>
    </Grid>

  );
}