// Adding a new Get Score function because if we change the scoring logic for GetScore then
// it causes errors with the other game modes.
export function GetScoreFreePlay(target: number, guess: number) {
  const difference = Math.abs(target - guess);
  if (difference <= 5) {
    return 5;
  }
  else if (difference <= 10) {
    return 2;
  }
  return 0;
}

export function GetScore(target: number, guess: number) {
  const difference = Math.abs(target - guess);
  if (difference <= 5) {
    return 5;
  }
  else if (difference <= 10) {
    return 2;
  }
  return 0;
}
