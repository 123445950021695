import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useStorageBackedState } from "../hooks/useStorageBackedState";
import { firestore_db } from "../../index";
import { onSnapshot, doc } from 'firebase/firestore';
import ava from 'animal-avatar-generator';
import { avatar_dict } from "../common/LandingPage";
import { BsQuestionCircle, } from "react-icons/bs";
import {
	Heading,
	Text,
	Container,
	Flex,
	Button,
	SkeletonCircle,
	HStack,
	VStack,
	useMediaQuery,
} from "@chakra-ui/react";

enum Stages {
	notMatching = "0",
	findingMatch = "1",
	noMatchFound = "2",
}

function findParameter(parameterName: string) {
	var result = null,
		tmp = [];
	window.location.search
		.slice(1)
		.split("&")
		.forEach(function (item) {
			tmp = item.split("=");
			if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
		});
	return result;
}

export function MatchQuestions() {
	const [widthLargerThan1300] = useMediaQuery('(min-width: 1300px)')
	const [heightLargerThan600] = useMediaQuery('(min-height: 600px)')

	// const [place, setPlace] = useStorageBackedState("", "place");
	// const [pet, setPet] = useStorageBackedState("", "pet");
	const [playerName, setPlayerName] = useStorageBackedState("", "name");
	const [avatar, setAvatar] = useStorageBackedState("", "avatar");
	const [findingMatch, setFindingMatch] = useStorageBackedState(Stages.notMatching, "finding_match");
	const history = useHistory();
	const [disabled, setDisabled] = useState(false);
	const [matchAttempt, setmatchAttempt] = useState(0);

	useEffect(()=>{
		setFindingMatch(Stages.notMatching)
		}, [])

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if(findingMatch === Stages.notMatching){
		var adduser_url = 'https://us-central1-guessync.cloudfunctions.net'

		if (document.location.hostname === "localhost") {
			adduser_url = 'http://localhost:5001/guessync/us-central1';
		}

		const assignmentId = findParameter("assignmentId");
		const hitId = findParameter("hitId");
		const turkSubmitTo = findParameter("turkSubmitTo");
		const workerId = findParameter("workerId");

		var response = null
		var user_id = null
		if (assignmentId != null && hitId != null && turkSubmitTo != null && workerId != null)
		{
			response = await axios.get(adduser_url + '/addUser?player_name=' + playerName 
										//  + "&place=" + place 
										//  + "&pet=" + pet 
										 + "&avatar=" + avatar
										 + "&assignmentId=" + findParameter("assignmentId")
										 + "&hitId=" + findParameter("hitId")
										 + "&turkSubmitTo=" + findParameter("turkSubmitTo")
										 + "&workerId=" + findParameter("workerId")
										 );
		}
		else{
			response = await axios.get(adduser_url + '/addUser?player_name=' + playerName 
										//  + "&place=" + place 
										//  + "&pet=" + pet 
										 + "&avatar=" + avatar
										 );
		}

		user_id = response.data.user_id;
		const chat_token = response.data.chat_token;
		localStorage.setItem('player_id', JSON.stringify(user_id));
		localStorage.setItem('chat_token', JSON.stringify(chat_token));
		setFindingMatch(Stages.findingMatch);
	}
		else{
			user_id = JSON.parse(localStorage.getItem("player_id") || "{}");
			var tryAgainURL: string = 'https://us-central1-guessync.cloudfunctions.net/tryAgain?&user_id=';

			if (document.location.hostname === "localhost") {
				tryAgainURL = 'http://localhost:5001/guessync/us-central1/tryAgain?&user_id=';
			}
			
			await axios.get(tryAgainURL + user_id);
			setFindingMatch(Stages.findingMatch);

		}
		
		onSnapshot(doc(firestore_db, "users", user_id), (doc) => {
			
			const user_data = doc.data();
			
			if (user_data !== undefined && user_data.room_id !== undefined && user_data.room_id !== '') {
				//setPartnerId(user_data.partner_id)
				const parameters = window.location.search

				localStorage.setItem('room_id', JSON.stringify(user_data.room_id));
				localStorage.setItem('partner_id', JSON.stringify(user_data.partner_id));
				localStorage.setItem('room_type', JSON.stringify(user_data.room_type));
				if(user_data.clue_giver !== undefined){
					localStorage.setItem('clue_giver', JSON.stringify(user_data.clue_giver));
				}

				history.push("/rooms/" + user_data.room_id + "/" + parameters);
			}
			else if (user_data !== undefined && user_data.status !== undefined && user_data.match_attempt !== undefined && user_data.status === "unmatched") {
				
				setmatchAttempt(user_data.match_attempt)
				setDisabled(false);
				setFindingMatch(Stages.noMatchFound);
			}
		});
	}

	/**
	 * We want our users to only have to click images for the match quesionnaire- so
	 * we create a custom radio button component that takes in an array of key-value pairs
	 * where the key is the label and the value is the react component containing an svg icon
	 * of the label.
	 */

	// Media Queries 

	if(matchAttempt >= 3){
		return (
            <div id="consent_text">
            <br/>
            <p>Unfortunately, we couldn't find a match for you. We will send you a $0.50 bonus for your time using your completed scheduling HIT. You don't need to do anything else to get this bonus, we will send the bonus in the next 24 hours. We are sorry for the inconvenience. Please return the HIT.
            </p>
            </div>
          );
	}

	var size = widthLargerThan1300 && heightLargerThan600 ? 250 : 100;
	var stringSize = widthLargerThan1300 && heightLargerThan600 ? '250' : '100';

	var heading_msg = ''
	var not_blinking = false
	if (findingMatch === Stages.notMatching) {
		heading_msg = "Let's find you a partner!"
		not_blinking = true
	}
	else if (findingMatch === Stages.findingMatch) {
		heading_msg = 'Finding a partner for you! Please wait, this might take upto a minute.'
		not_blinking = false
	}
	else {
		heading_msg = "Sorry! We could not find a match."
		not_blinking = true
	}
	const parameters = window.location.search

	return (
		<Flex align="center" w="100vw" h="100vh">
			<Container
				pt={heightLargerThan600 ? 10 : 5}
				pb={heightLargerThan600 ? 20 : 10}
				bg="#EFEEEE"
				borderRadius="20px"
				minWidth="45vw"
				display="flex"
				alignContent="center"
				margin={{ base: '10px', sm: 'auto' }}
			>
				<VStack width="full" spacing="2vh">
					<Heading textAlign={"center"} fontSize={{ base: '1.75rem', sm: '2.0rem' }} fontFamily="Reem Kufi">
						{heading_msg}<br/>(Attempt {(matchAttempt+1)} of 3)
					</Heading>
					<HStack spacing="5vw">
						<VStack>
							<span dangerouslySetInnerHTML={{ __html: ava(avatar_dict[avatar], { size: size, 'round': true, 'blackout': false }) }} />
							<Text fontFamily="Reem Kufi" fontSize={{ base: '1.75rem', sm: '2.0rem' }}>
								{playerName}
							</Text>
						</VStack>
						<VStack>
							<SkeletonCircle size={stringSize} isLoaded={not_blinking}>
								<BsQuestionCircle color='grey' size={size} />
							</SkeletonCircle>
							<Text fontFamily="Reem Kufi" color='grey' fontSize={{ base: '1.75rem', sm: '2.0rem' }}>
								Who?
							</Text>
						</VStack>
					</HStack>

					{(findingMatch === Stages.noMatchFound) && (parameters !== "") &&
						<Text fontFamily="Reem Kufi" color='grey' fontSize={{ base: '1rem', sm: '1.2rem' }}>
							We need two players to play this game. Please try matching again or return the HIT.
						</Text>}

					{(findingMatch === Stages.notMatching || findingMatch === Stages.noMatchFound) &&
						<Button disabled={disabled}
							onClick={
								(e) => {
									if (disabled) {
										return;
									  }
									setDisabled(true);
									handleSubmit(e); 
								}
							}
						>
							{findingMatch === Stages.notMatching ? "Match!" : "Try Again"}
						</Button>}
				</VStack>
			</Container>
		</Flex>
	);
}
