import "@fontsource/reem-kufi";

export const Button = {
    // The styles all button have in common
    baseStyle: {
        border: '2px',
        fontFamily: 'Reem Kufi',
        fontWeight: 'semibold',
    },
    sizes: {
        lg: {
            width: 200,
            height: 55,
            borderRadius: 20,
        },
        sm: {
            width: 150,
            height: 45,
            borderRadius: 20,
        },
    },
    variants: {
        solid: {
            bg: '#E2E2E2',
            fontSize: '25px',
        },
    },
    defaultProps: {
        variant: 'solid',
        size: 'lg',
    },
}