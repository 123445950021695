import {  useContext, } from "react";
import { GameModelContext } from "../../state/GameModelContext";
import { avatar_dict } from "./LandingPage";
import { HStack, VStack, Text, Avatar, useMediaQuery, } from '@chakra-ui/react';
import avatar from 'animal-avatar-generator';
import { GamePhase } from './../../state/GameState'

function AvatarHeader(props: {
  widthLargerThan1300: boolean,
  heightLargerThan600: boolean,
  player_avatar_svg: string,
  player_role:string,
  partner_avatar_svg: string,
  partner_role:string,
  partner_name: string,
  score: number,
  game_phase:GamePhase
}) {

  var fontSize = props.widthLargerThan1300 && props.heightLargerThan600 ? "2.0rem" : "1.5rem";
  var score = null
  if(props.game_phase === GamePhase.Full){
   score = (
    <Text mb={{ base: 0, lg: 12 }} fontFamily="Reem Kufi" fontSize={fontSize} textColor="white">
      <b>Score: </b>{props.score} points
    </Text>
  );}
  else{
    score = (
      <Text mb={{ base: 0, lg: 12 }} fontFamily="Reem Kufi" fontSize={fontSize} textColor="white">
        <b>Trial Round</b>
      </Text>
  );}

  return (
    <VStack mt={{base: 32, lg: 0}} spacing={0}>
      {props.widthLargerThan1300 && props.heightLargerThan600 ? score: null}
      <HStack spacing={20}>
        <VStack>
          <Avatar bottom={{base: 2, lg: 25}} icon={<span dangerouslySetInnerHTML={{ __html: props.player_avatar_svg }} />} />
          <Text fontFamily="Reem Kufi" fontSize={fontSize} textColor="white" textAlign={"center"}>
            You<br/>{props.player_role}
          </Text>
        </VStack>
        {!props.widthLargerThan1300 || !props.heightLargerThan600 ? score : null}
        <VStack>
          <Avatar bottom={{base: 2, lg: 25}} icon={<span dangerouslySetInnerHTML={{ __html: props.partner_avatar_svg }} />} />
          <Text fontFamily="Reem Kufi" fontSize={fontSize} textColor="white" textAlign={"center"}>
            {props.partner_name}<br/>
            {props.partner_role}
          </Text>
        </VStack>
      </HStack>
    </VStack>
  );
}

export function GameHeader(props: { username: string, player_role: string }) {
  const [widthLargerThan1300] = useMediaQuery('(min-width: 1300px)');
  const [heightLargerThan600] = useMediaQuery('(min-height: 600px)');
  const { gameState } = useContext(GameModelContext);

  const player_avatar = JSON.parse(localStorage.getItem("avatar") || "{}");
  const partner_role = (props.player_role === "Guesser") ? "Clue Giver" : "Guesser"
  const partner_name = JSON.parse(localStorage.getItem("partner_name") || "{}");
  const partner_avatar = JSON.parse(localStorage.getItem("partner_avatar") || "{}");

  let player_avatar_svg = "";
  if (["0", "1", "2", "3", "4", "5"].includes(player_avatar)) {
    player_avatar_svg = avatar(avatar_dict[player_avatar], { size: widthLargerThan1300 && heightLargerThan600 ? 90 : 70, 'round': true, 'blackout': false });
  }

  let partner_avatar_svg = "";
  if (["0", "1", "2", "3", "4", "5"].includes(partner_avatar)) {
    partner_avatar_svg = avatar(avatar_dict[partner_avatar], { size: widthLargerThan1300 && heightLargerThan600 ? 90 : 70, 'round': true, 'blackout': false });
  }


  return (
    <HStack spacing="75vw">
      <AvatarHeader
        heightLargerThan600={heightLargerThan600}
        widthLargerThan1300={widthLargerThan1300}
        player_avatar_svg={player_avatar_svg}
        player_role={props.player_role}
        partner_avatar_svg={partner_avatar_svg}
        partner_role={partner_role}
        partner_name={partner_name}
        score={gameState.freePlayScore}
        game_phase={gameState.gamePhase}
      />
    </HStack>
  );
}
