import React from "react";
import { useHistory } from "react-router-dom";
import { GameTitle } from "./Title";
import { useStorageBackedState } from "../hooks/useStorageBackedState";
import ava from 'animal-avatar-generator';
import "bootstrap/dist/css/bootstrap.min.css";
import { IoIosArrowForward, IoIosArrowBack, } from "react-icons/io";
import {
  Button,
  IconButton,
  Text,
  Input,
  VStack,
  HStack,
  Container,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";


export const avatar_dict: { [key: string]: any } = {'0':'Natashia Harrold',
                      '1':'Shera Reece',
                      '2':'Onita Esperanza',
                      '3':'Kaye Lanclos',
                      '4':'Nickie Tortora',
                      '5':'Nobuko Frary'};


export function LandingPage() {
  const [heightLargerThan600] = useMediaQuery('(min-height: 500px)');
  const history = useHistory();
  const [playerName, setPlayerName] = useStorageBackedState("", "name");
  const [avatar, setAvatar] = useStorageBackedState(Math.floor(Math.random() * 6).toString(), "avatar");



  localStorage.setItem("finding_match", JSON.stringify(""))
  localStorage.setItem("partner_id", JSON.stringify(""))
  localStorage.setItem("partner_place", JSON.stringify(""))
  localStorage.setItem("partner_pet", JSON.stringify(""))
  localStorage.setItem("partner_name", JSON.stringify(""))
  localStorage.setItem("partner_avatar", JSON.stringify(""))

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

  }

  // Define components for when height is less than 600
  // Probably redundant but breakpoints aren't reacting to only height changes? So
  // I could only get the sizing to change by doing this manually. Probably better ways of doing this?
  const inputHeightChange = (
    <Input
      height='55px'
      width='250px'
      border="2px"
      borderRadius="20px"
      variant="outline"
      placeholder="Enter your name"
      background="#E2E2E2"
      onChange={(e) => setPlayerName(e.target.value)}
      fontSize={{ base: '22px', sm: '25' }}
      textAlign={"center"}
    />
  );
  const textHeightChange = (
    <Text m={0} fontSize="1.4rem" fontFamily="Reem Kufi">
      Choose your avatar!
    </Text>
  );

  const avatarHeightChange = (
    <HStack>
      <IconButton
        w='30px'
        h='30px'
        icon={<IoIosArrowBack />}
        aria-label="Previous"
        onClick={() => {

          if (avatar === "0") { setAvatar("5") }
          else {
            setAvatar((parseInt(avatar) - 1) + "")
          }

        }} />
      <span dangerouslySetInnerHTML={{ __html: ava(avatar_dict[avatar], { size: 80, 'round': true, 'blackout': false }) }} />
      <IconButton
        w='30px'
        h='30px'
        icon={<IoIosArrowForward />}
        aria-label="Next"
        onClick={() => {

          setAvatar(((parseInt(avatar) + 1) % 6) + "")

        }}>
        Next
      </IconButton>
    </HStack>
  );

  if (avatar === "NaN") { setAvatar("0") }
  return (
    <Flex align="center" w="100vw" h="100vh">
      <Container
        overflowY="hidden"
        pt={heightLargerThan600 ? 10 : 3.0}
        pb={heightLargerThan600 ? 10 : 3.0}
        bg="#EFEEEE"
        borderRadius="20px"
        display='flex'
        margin={{ base: '10px', sm: 'auto' }}
        centerContent
      >
        <VStack spacing="2vh">
          <GameTitle />
          {heightLargerThan600 ?
          <Input
            height={{ base: '55px', sm: '65px' }}
            width={{ base: '250px', sm: '300px' }}
            border="2px"
            borderRadius="20px"
            variant="outline"
            placeholder="Enter your name"
            background="#E2E2E2"
            onChange={(e) => setPlayerName(e.target.value)}
            fontSize={{ base: '22px', sm: '25' }}
            textAlign={"center"}
          />
          :
          inputHeightChange}
          {heightLargerThan600 ?
            <Text m={0} fontSize={{ base: '1.4rem', sm: '2.0rem' }} fontFamily="Reem Kufi">
              Choose your avatar!
            </Text>
            :
            textHeightChange}
          {heightLargerThan600 ?
            <HStack>
              <IconButton
                w={{ base: '30px', sm: '50px' }}
                h={{ base: '30px', sm: '50px' }}
                icon={<IoIosArrowBack />}
                aria-label="Previous"
                onClick={() => {

                  if (avatar === "0") { setAvatar("5") }
                  else {
                    setAvatar((parseInt(avatar) - 1) + "")
                  }

                }} />
              <span dangerouslySetInnerHTML={{ __html: ava(avatar_dict[avatar], { size: 100, 'round': true, 'blackout': false }) }} />
              <IconButton
                w={{ base: '30px', sm: '50' }}
                h={{ base: '30px', sm: '50' }}
                icon={<IoIosArrowForward />}
                aria-label="Next"
                onClick={() => {

                  setAvatar(((parseInt(avatar) + 1) % 6) + "")

                }}>
                Next
              </IconButton>
            </HStack>
            :
            avatarHeightChange}
          <Button
            size={heightLargerThan600 ? 'lg': 'sm'}
            onClick={() => {
              //move to questionnaire page
              if (playerName === "") { alert("Please enter player name") }
              else if (avatar === "") { alert("Please select avatar") }
              else {
                const parameters = window.location.search
                history.push("/tutorial" + parameters);
              }
            }}
          >
            Play!
          </Button>
          {/* <RulesButton/> */}
          {/* <Button
            size={heightLargerThan600 ? 'lg': 'sm'}
            onClick={() => {
              history.push("/about");
            }}
          >
            About
          </Button> */}
        </VStack>
      </Container>
    </Flex>
  );
}


