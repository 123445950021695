import { setDoc } from "firebase/firestore";
import { doc } from "firebase/firestore";
import { firestore_db } from "../..";


  

export async function recordPlayedOnce(){

    function findParameter(parameterName: string) {
        var result = null,
          tmp = [];
        window.location.search
          .slice(1)
          .split("&")
          .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
          });
        return result;
      }



    const workerId = findParameter("workerId")|| 'unknown'
    const current_time = Math.round(Date.now() / 1000)
    const left_midway_ref = doc(firestore_db, "played_once", workerId)
    await setDoc(left_midway_ref, {"worker_id":workerId, play_time:current_time})
}