

export const demQuestions: [string, number][][] = [[["What percent of Democrats believe that climate change has been mostly due to human activity?",69],
["What percent of Democrats are unwilling to pay much higher prices in order to protect the environment?",17]],
[["What percent of Democrats support the death penalty for convicted murderers?",44]],
[["What percent of Democrats oppose making free trade agreements with other countries?",7]],
[["What percent of Democrats support lowering the eligibility age for Medicare from 65 to 50?",77]],
[["What percent of Democrats feel that courts deal too harshly with criminals?",40],
["What percent of Democrats say that the US spends too much on reducing crime rates?",8]],
[["What percent of Democrats believe that the legacy of slavery affects the position of black people in society today?",97]],
[["What percent of Democrats think that high-income individuals pay too little in taxes?",75]],
[["What percent of Democrats say that transgender people face no discrimination at all in the US?",1]],
[["What percent of Democrats support requiring showing a government photo ID when voting?",48],
["What percent of Democrats say that eligible voters are never denied the right to vote?",7]],
[["What percent of Democrats say that the US spends too little on assistance to the poor?",44]]]

//[
    // ["What percentage of Republicans earn more than $240,000 per year?", 2],
    // ["What percentage of Republicans say the police never use more force than necessary?", 3],
    // ["What percentage of Republicans favor laws to protect gays and lesbians against job discrimination?", 79],
    // ["What percentage of Republicans support background checks for gun purchases at gun shows or private sales?", 80]
//]



export const repQuestions: [string, number][][] = [[["What percent of Republicans say they would be pleased if the supreme court reduced abortion rights?",43], ["What percent of Republicans say that abortion should never be permitted?",19]],
[["What percent of Republicans are willing to open up protected nature areas for economic development?",16],["What percent of Republicans say that the US spends too much on alternative energy sources?",23]],
[["What percent of Republicans support laws that protect gays and lesbians against job discrimination?",81]],
[["What percent of Republicans support requiring background checks for gun purchases at gun shows or private sales?",82],
["What percent of Republicans say that the government should make it easier to buy a gun?",11]],
[["What percent of Republicans say that the US spends too much on the nation's health?",16]],
[["What percent of Republicans support making all unauthorized immigrants felons and sending them back?",24],
["What percent of Republicans support sending back children who were brought to the US illegally and have lived here for 10+ years?",21]],
[["What percent of Republicans say that the federal minimum wage should be decreased?",4]],
[["What percent of Republicans oppose requiring employers to offer paid leave to parents of new children?",13]],
[["What percent of Republicans say that the police officers never use more force than necessary?",3],
["What percent of Republicans support requiring police officers to wear body cameras while on duty?",88]],
[["What percent of Republicans say that blacks face no discrimination at all in the US?",5],
["What percent of Republicans believe that the legacy of slavery affects the position of black people in society today?",68]],
[["What percent of Republicans think that high-income individuals pay the right amount in taxes?",29]],
[["What percent of Republicans say that eligible voters are never denied the right to vote?",23]]]



//[
    // ["What percentage of Democrats are union members?", 13],
    // ["What percentage of Democrats say police officers always use more force than necessary?", 13],
    // ["What percentage of Democrats favor the death penalty for persons convicted of murder?", 42],
    // ["What percentage of Democrats favor increasing federal spending on dealing with crime?", 44],    
//]

export const nonpolQuestions: [string, number][] = [
    ['What percent of adults say they would like to bring back dinosaurs?',
        12],
       ['What percent of adults say that chocolate glazed donuts are their favorite donuts?',
        12],
       ['What percent of adults in a relationship met their partner online?',
        12],
       ['What percent of adults have at least one tattoo?', 26],
       ['What percent of adults are single?', 31],
       ['What percent of adults consider a hotdog to be a sandwich?', 33],
       ['What percent of adults believe in ghosts?', 36],
       ['What percent of adults like their eggs scrambled?', 37],
       ['What percent of adults believe in UFOs?', 39],
       ['What percent of dog owners got their dogs from a shelter?', 40],
       ['What percent of adults set an alarm but do not snooze when waking up?',
        40],
       ['What percent of pet owners dress up their pets for halloween?',
        45],
       ['What percent of adults say they drink coffee everyday?', 62],
       ["What percentage of TV-owning adults watched Neil Armstrong set foot on the moon?",94],
       ['What percent of adults say they have had a teacher who changed their life for the better?',
        51],
       ['What percent of households are dog owners?', 54],
       ['What percent of adults in a relationship say they are satisfied with their relationship?',
        94]
    ]

        // [
        //     ["What percentage of households do not have access to a car?", 9],
        //     ["What percentage of households own a pet?", 61],
        //     ["What percentage of Americans are right-handed?", 89],
        //     ["What percentage of adults drink coffee everyday?", 62],
        //     ["What percentage of Americans have tattoos?", 30],
        //     ["What percentage of men say football is their favorite sport?", 40],
        //     ["What percentage of Americans are fully vaccinated?", 65],
        //     ["What percentage of adults have used a dating site?", 30],
        //     ["What percentage of children have peanut allergy?", 2],
        //     ["What percentage of TV-owning Americans watched Neil Armstrong set foot on the moon?",94],
        //     ["What percentage of households own two or more fridges?",30],
        //     ["What percentage of adults smoke cigarettes?",13],
        //     ["What percentage of adults are currently married?",53],
        //     ["What percentage of Americans are millennials?",23],
        //     ["What percentage of adults invest in the stock market?", 56],
        // ]