import { useHistory } from "react-router-dom";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  Text
} from '@chakra-ui/react'

export function ViewQuestions(props: {
  questions: any[],
  isOpen: boolean,
  final_score: number
}) {
  const history = useHistory();
  const parameters = window.location.search

  const setSurvey = () => {
    
    history.push("/postgamesurvey" + parameters)
  }

  

  return (
    <Modal size="4xl" isOpen={props.isOpen} onClose={() => {}} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius={20}>
        <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
          <Center>Final Score: {props.final_score} points</Center>
          
        </ModalHeader>
        <ModalBody fontFamily="Reem Kufi" fontSize="1.5rem">
          <Table fontFamily="Reem Kufi" variant="simple">
            <Thead>
              <Tr>
                <Th>What percentage of...</Th>
                <Th>Final Guess</Th>
                <Th color={"black"}>Correct Answer</Th>
                <Th>Points Scored</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                props.questions.map(question => {

                  var q = question.question.slice(16)

                  var score_color = "green"
                  if (question.points === 2){score_color = 'orange'}
                  else if (question.points === 0){score_color = 'red'}

                  return (
                    
                    <Tr key={question.question}>
                      <Td>{q.charAt(0).toUpperCase() + q.slice(1)}</Td>
                      <Td color={"grey"}>{question.final_g}%</Td>
                      <Td color={"black"}>{question.answer}%</Td>
                      <Td color={score_color}>+{question.points}</Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
        <Text fontFamily="Reem Kufi" >
          All answers are from surveys by nonpartisan sources such as Gallup and YouGov.
            </Text>
          {(parameters !== "") && <Button colorScheme='green' mr={3} onClick={setSurvey}>
            Game Survey
          </Button>}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}