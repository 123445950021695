import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Heading,
} from "@chakra-ui/react";
import axios from "axios";
import { firestore_db } from "../..";
import { doc, getDoc } from "firebase/firestore";

function findParameter(parameterName:string) {
    var result = null,
        tmp = [];
    	window.location.search
        .slice(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}



export function ConsentPage() {
    const assignmentId = findParameter("assignmentId")
    const history = useHistory();
    const [hasPlayed, setPlayed] = useState(false);
    const [cutoff_time, setCutoff_time] = useState(2652964660);


    useEffect(() => {

        const checktime = async () => {
            const cutoff_ref = doc(firestore_db, "cutoff_time", "cutoff_time")
            const cutoffSnap = await getDoc(cutoff_ref);

            if (cutoffSnap.exists()) {
                    const user_data = cutoffSnap.get("cutoff_time")
                    setCutoff_time(parseInt(user_data))
                }
          }

        const leavercheck = async () => {
            const worker_id = findParameter("workerId") || 'none'
    
            const left_midway_ref = doc(firestore_db, "played_once", worker_id)
            const docSnap = await getDoc(left_midway_ref);
            const check = docSnap.exists()
            setPlayed(check)
          }
        
        leavercheck()
        checktime()
    
    },[])

    

    const handleSubmit = async (e: React.FormEvent) => {
        var adduser_url = 'https://us-central1-guessync.cloudfunctions.net'
    
        if (document.location.hostname === "localhost") {
            adduser_url = 'http://localhost:5001/guessync/us-central1';
        }
    
        await axios.get(adduser_url + '/addConsent?' 
                                         + "&assignmentId=" + findParameter("assignmentId")
                                         + "&hitId=" + findParameter("hitId")
                                         + "&workerId=" + findParameter("workerId")
                                         )
        
    
        const parameters = window.location.search
    
        history.push("/" + parameters)
    }

    
    var current_date = new Date()
    var current_time = Date.now()/1000
    var cutoff_date = new Date(cutoff_time * 1000)
    
    if(current_time > cutoff_time){
        return (
            <div id="consent_text">
            <br/>
            <p>Sorry, it's {current_date.toLocaleString('en-US', {timeZone: 'America/New_York'})} ET. This game can only be started before {cutoff_date.toLocaleString('en-US', {timeZone: 'America/New_York'})} ET. Please return the HIT.
            </p>
            </div>
          );
    }

    let isMobile = window.matchMedia("only screen and (max-width: 400px)").matches;

    if (isMobile) {
        return(<div id="consent_text"><div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            Unfortunately, at this time the HIT will not work on small screens. Please switch to a laptop/desktop or return the HIT. </div></div>)
    }

    if(hasPlayed)
    {
        return (
            <div id="consent_text">
            <br/>
            <p>Our records show that you have already started this game once. You cannot play the game again.
            </p>
            </div>
          );
    }
    
  return (
    <div id="consent_text">
    
   
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
    <Heading>

    Consent To Be Part Of A Research Study

    </Heading>

    </div>
    <br/>
    <h2><strong>READ THIS BEFORE STARTING THE HIT</strong></h2>

    <p>
    <ul className="consent_ul">
    <li><strong>Make sure that you are are available for the full duration of the game (15-20 minutes) before starting the HIT as you will be playing a timed game with another Turker in realtime.</strong></li>
    <li><strong>You will NOT be paid if you leave the HIT midway.</strong></li>
    <li><strong>If your game partner leaves after starting but before completing the game, don't worry, you will still be paid. After upto 2 minutes of partner inactivity, the game will redirect you to the post-game survey and allow you to submit the HIT.</strong></li>
    <li><strong>If you do not get matched with a partner after three matching attempts, we will pay you $0.50 as bonus on your previous scheduling HIT. The game can't be played without a partner, please return the HIT.</strong></li>
    <li><strong>If you suspect that your partner has left the game, DO NOT LEAVE. Please wait for the game to redirect you. We cannot pay you if you leave.</strong></li>
    <li><strong>Do not refresh the page when playing the game.</strong></li>
    <li><strong>Play the game only using a desktop or laptop. It will not work on tablets or phones.</strong></li>
    </ul>
    </p>
    <br/>
    <p>Play GuesSync! - a fun 2-player cooperative party game. 
    <ul className="consent_ul">
        <li>Follow the in-game tutorial carefully.</li>
        <li>You will be <strong>randomly matched</strong> with a fellow Turker and play 5 rounds (+ 2 practice rounds) together.</li>
        <li>In the game, you and your partner will come up with <strong>clues to guess the correct answer</strong> in each round.</li>
        <li>Once the game is over, you will be asked to <strong>complete a survey</strong> to finish the HIT.</li>
        <li>Through your survey responses, we will be assessing the game's features, player experiences and how playing the game might affect you.</li>
    </ul>
     
    </p>
    
    <br/>
    <p>
    This form contains information that will help you decide whether to join the study. Taking part in this research project is voluntary. 
    Please read through the information below and then click on the consent button at the bottom of the page, or return the HIT if you do not consent.
    </p>
    <br/>
    <hr/>
    <br/>
    
    
    
    <b>1.  Key information about the researchers and this study </b>
    <br/>
    
    Principal Investigator: <br/>Ashwin Rajadesingan, PhD Candidate, University of Michigan School of Information<br/>
    <br/>
    Co-Investigators: <br/>Daniel Choo, Jessica Zhang and Mia Inakage, BS students, University of Michigan School of Information <br/>
    <br/>
    Faculty Advisors: <br/>
    Paul Resnick and Ceren Budak, Faculty, University of Michigan School of Information<br/>
     <br/>
    <b>2. Purpose of this study</b><br/>
     
    This study is conducted by researchers at the University of Michigan to understand how participating in a cooperative trivia game might affect the players.
    
    <br/><br/>
     
    <b>3 Who can take part in this study?</b><br/>
     
    Participants are recruited through Amazon Mechanical Turk.
     <br/><br/>
     <b>4.  Information about study participation</b><br/>
     
    <b>4.1 What will happen to me in this study?</b><br/>
    If you agree to participate, you will be redirected to the game page where you will be matched with another player. You will complete 5 rounds (+ 2 practice rounds) of the game with the other player before completing the postgame survey.
    <br/><br/>
    <b>4.2 What is the duration of this study?</b><br/>
    The study should not exceed 15-20 minutes.
    <br/><br/>
    <b>5.  Information about study risks and benefits</b><br/>
     
    <b>5.1 What risks will I face by taking part in the study?  What will the researchers do to protect me against these risks?</b><br/>
    There are no risks in participating in this research beyond those experienced in everyday life. As noted below, your responses and game chat will be confidential. While we strive to use industry-standard security technology to help protect your personal or demographic information, because this study uses an online questionnaire, we are unable to guarantee complete Internet security as transmissions can be intercepted and IP addresses can be identified. However, we will make every effort to protect confidentiality of your information and the collected data will be password-protected.
     <br/><br/>
    <b>5.2 How could I benefit if I take part in this study? </b><br/>
    You may not receive any personal benefits from being in this study
     <br/><br/>

    <b>6. If I want to stop participating in the study, what should I do?</b><br/>
    You are free to leave the study at any time. If you leave the study before it is finished, there will be no penalty to you. If you decide to leave the study before it is finished, please tell one of the persons listed in Section 9. “Contact Information”. If you choose to tell the researchers why you are leaving the study, your reasons may be kept as part of the study record. The researchers will keep the information collected about you for the research unless you ask us to delete it from our records. If the researchers have already used your information in a research analysis it will not be possible to remove your information.
     <br/><br/>

     
    <b>7. Will I be paid or given anything for taking part in this study?</b><br/>
    You will be paid $3.75 for completing the game and the survey.
     <br/><br/>
     
    <b>8 How will the researchers protect my information?</b><br/>
    We plan to publish the results of this study. We will protect the confidentiality of your study-related information by storing all study data in a private, secure server as managed by University of Michigan’s Information Technology support staff. 
     
    We may use or share your research information for future research studies. If we share your information with other researchers it will be de-identified, which means that it will not contain your name or other information that can directly identify you. This research may be similar to this study or completely different. We will not ask for your additional informed consent for these studies.
     <br/><br/>
    <b>9. Contact information</b>
    <br/>
    
    Please contact the researchers listed below to:<br/>
    ·      Obtain more information about the study<br/>
    ·      Ask a question about the study procedures<br/>
    ·      Leave the study before it is finished<br/>
    ·      Express a concern about the study<br/>
     <br/>
    Principal Investigator: Ashwin Rajadesingan<br/>
    Email: arajades@umich.edu<br/>
    Phone: 734-763-1251<br/>
     <br/>
    Faculty Advisor: Paul Resnick<br/>
    Email: presnick@umich.edu<br/>
    Phone: 734-647-9458 <br/>
     <br/>
    Faculty Advisor: Ceren Budak<br/>
    Email: cbudak@umich.edu<br/>
    Phone: 734/763-2284 <br/>
     <br/>
    The University of Michigan Health Sciences and Behavioral Sciences Institutional Review Board has determined that this research is exempt from IRB oversight.
    <br/><br/>
    <h4>Consent/Assent to Participate in the Research Study</h4>
    I have read this form and I am aware that I am being asked to participate in a research study. I voluntarily agree to participate in this study.
    <br/>
    <br/>
    {(assignmentId !== undefined && assignmentId !== "ASSIGNMENT_ID_NOT_AVAILABLE") && <Button
        onClick={
            (e) => {
                handleSubmit(e); // Why does this work
                return;
            }
        }
    >
       Consent
    </Button>}
    </div>
    
  );
}



