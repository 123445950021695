// import { useEffect, useState } from "react";

// export const Timer = (props:any) => {
//     const {initialMinute = 0,initialSeconds = 0} = props;
//     const [ minutes, setMinutes ] = useState(initialMinute);
//     const [seconds, setSeconds ] =  useState(initialSeconds);
//     useEffect(()=>{
//     let myInterval = setInterval(() => {
//             if (seconds > 0) {
//                 setSeconds(seconds - 1);
//             }
//             if (seconds === 0) {
//                 if (minutes === 0) {
//                     clearInterval(myInterval)
//                 } else {
//                     setMinutes(minutes - 1);
//                     setSeconds(59);
//                 }
//             } 
//         }, 1000)
//         return ()=> {
//             clearInterval(myInterval);
//           };
//     });
  
//     if(minutes === 0 && seconds === 0){
//         return <>(00:00)</>
//     }
//     else{
//         return  <>({minutes}:{seconds < 10 ?  `0${seconds}` : seconds})</>
//     }
       
//     }

import { useTimer } from 'react-timer-hook';

export function Timer({expiryTimestamp}:any) {

    const {
    seconds,
    minutes
    } = useTimer({ expiryTimestamp, onExpire: () =>{}});


    if(minutes === 0 && seconds === 0){
        return <>(00:00)</>}
    else{
        return  <>({minutes}:{seconds < 10 ?  `0${seconds}` : seconds})</>
    }
}


