import { useState } from "react";
import { IoIosArrowForward, IoIosArrowBack, } from "react-icons/io";
import {
  Button,
  Center,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useMediaQuery,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  useDisclosure,
} from '@chakra-ui/react'
import { useHistory } from "react-router-dom";
import { useEffect,useRef } from "react";

export function Tutorial() {
  const [widthLargerThan1000] = useMediaQuery('(min-width: 1000px)')
  const [heightLargerThan800] = useMediaQuery('(min-height: 800px)')

  const [step, setStep] = useState('0');
  const [done, setDone] = useState(false); 
  const [tutorialTime,setTutorialTime] = useState(0);
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  useEffect(()=>{
    const tutorial_start_time = Math.round(Date.now() / 1000)
    localStorage.setItem("tutorial_start_time", JSON.stringify(tutorial_start_time))}, [])

  const close = () => {
    const tutorial_end_time = Math.round(Date.now() / 1000)
      localStorage.setItem("tutorial_end_time", JSON.stringify(tutorial_end_time))
      const parameters = window.location.search
      history.push("/matching" + parameters); 
    
  }

  let num_slides: number = 7;
  var imgWidth = heightLargerThan800 ? "600px" : "450px";
  var imgWidthLast = heightLargerThan800 ? "500px" : "350px";
  const tutorial_dict: { [key: string]: any } = {
    '0': <img src="https://i.imgur.com/f6bhAaa.png" width={imgWidth} alt=""/>,
    '1': <img src="https://i.imgur.com/JX5Jz6l.png" width={imgWidth} alt=""/>,
    '2': <img src="https://i.imgur.com/NOdnMUC.png" width={imgWidth} alt=""/>,
    '3': <img src="https://i.imgur.com/ADbZWkH.png" width={imgWidth} alt=""/>, 
    '4': "", 
    '5': <img src="https://i.imgur.com/YHO1U6Q.png" width={imgWidth} alt=""/>, 
    '6': <img src="https://i.imgur.com/veBTeOW.png" width={imgWidthLast} alt=""/>, 
  };

  const tutorialtext_dict: { [key: string]: any } = {
    '0': "Each round begins with a question. You and your partner try to independently guess as close to the correct answer as possible. (time limit: 60 seconds)",
    '1': "Then, one of you will be shown the correct answer (here, 70%). That person comes up with a clue using the provided scale to help their partner guess correctly. Here, a good clue would be something that the partner can identify as being more cold than hot as the target is closer to the cold end of the scale. (time limit: 120 seconds)",
    '2': "'Lemonade' might be a good clue here since it's usually had cold. If the correct answer was 5% (close to hot), 'sun' might be a good clue. If the answer was 95% (close to cold), 'arctic' might be a good clue. Your partner makes the team's final guess using the clue and the past guesses. (time limit: 60 seconds)",
    '3': "The scales and questions change each round. For the same answer (70%), if the scale was Light-Heavy, then a good clue might be 'suitcase' as it is usually on the heavier side. If the correct answer was 95% (mostly heavy), 'elephant' might be a good clue. If the answer was 5%, then 'feather' might be a good clue.",
    '4': "",
    '5': "Your team gets 5 points if the final guess is within 5% from the correct answer and 2 points if the final guess is within 10% from the correct answer. You will play 5 (+2 practice) rounds. The questions and scales change each round.", 
    '6': "Remember this is a cooperative game, it takes teamwork to win! Make sure to chat and help out your partner before and after each round.", 
  };

  var titleSize = heightLargerThan800 ? "2.0rem" : "1.3rem";
  var fontSize = heightLargerThan800 ? "1.4rem" : "1.1rem";
  var size = widthLargerThan1000 ? '4xl' : '2xl';
  

  return (
    <>
      <Modal size={size} isOpen={true} onClose={close} closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent borderRadius={20} display='flex'>
        <ModalHeader fontSize={titleSize} fontFamily="Reem Kufi" textAlign={"center"}>
          TUTORIAL
        </ModalHeader>
        <ModalBody fontFamily="Reem Kufi" fontSize={fontSize}>
            
            {step === '0'?<>All game questions are about <b>US adults</b> and are from surveys by reputed nonpartisan sources such as <b>Gallup and YouGov</b>. </>:null}
            {step === '4'?<>Rules for coming up with clues:
            <ul className="consent_ul">
            <li>Should only be one or two words long.</li>
            <li>Cannot have more than 20 letters.</li>
            <li>Cannot include numbers or special characters.</li>
            <li>Cannot include the scale words themselves.</li>
            <li>Cannot include quantifier words like lot, little and direction-related words like left/right, higher/lower.</li>
            <li>Cannot include words like same and correct that convey the answer without using the provided scales.</li>
            
            </ul>
            <br/>
            Please use the scales to provide clues. It's way more interesting that way!
            </>:null}
            
            {tutorialtext_dict[step]}
          <Center>
                <IconButton
                  className="arrow_buttons"
                  isDisabled={(step === "0")}
                  //w={50}
                  //h={50}
                  boxSize={{base: "10px", sm:"20"}}
                  icon={<IoIosArrowBack />}
                  aria-label="Previous"
                  onClick={() => {
                    if (step === "0") { setStep("6") }
                    else {
                      setStep((parseInt(step) - 1) + "")
                    }
                  }} />
              {tutorial_dict[step]}
              <IconButton
                className="arrow_buttons"
                isDisabled={(step === "6")}
                //w={50}
                //h={50}
                boxSize={{base: "10px", sm:"20"}}
                icon={<IoIosArrowForward />}
                aria-label="Next"
                onClick={() => {
                  setStep(((parseInt(step) + 1) % num_slides) + "")

                  // We subtract two to make sure it updates when they've reached the last slide
                  if (parseInt(step) === num_slides - 2) {
                    setDone(true);
                  }
                }}>
                Next
              </IconButton>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button size={heightLargerThan800 ? 'lg' : 'sm'} disabled={!done} color='black' colorScheme='green' mr={3} onClick={()=>{
            const tutorial_end_time = Math.round(Date.now() / 1000)
            var start_time = JSON.parse(localStorage.getItem("tutorial_start_time") || "{}");
            var tutorial_time = tutorial_end_time - start_time
            setTutorialTime(tutorial_time)
            if(tutorial_time < 60)
            { 
              return onOpen()
            }
            else{
            return close()
              }
            }
            }>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef.current}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Slow down...</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            You spent {tutorialTime} seconds on the tutorial. Please take atleast a minute to read the tutorial carefully. It will significantly improve your game experience.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef.current} onClick={onClose}>
              Okay
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>

  )
}