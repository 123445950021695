import { RoundPhase, GameState, GamePhase } from "./GameState";
import { RandomSpectrumTarget } from "./RandomSpectrumTarget";

export function NewRound(
  playerId: string,
  gameState: GameState
): Partial<GameState> {



  const newState: Partial<GameState> = {
    clueGiver: playerId,
    roundPhase: RoundPhase.AnswerQuestion,
    deckIndex: gameState.deckIndex + 1,
    turnsTaken: gameState.turnsTaken + 1,
    cluegiver_initial_guess_complete:false,
    guesser_initial_guess_complete:false,
    cluegiver_initial_guess:50,
    guesser_initial_guess:50,
    guess:50,
    cluegiver_next_round_ready:false,
    guesser_next_round_ready:false,
    spectrumTarget: RandomSpectrumTarget(),
    gamePhase: (gameState.turnsTaken>=0)? GamePhase.Full: GamePhase.Trial
  };

  

  return newState;
}
