
export function EndPage() {
   
  return (
    <div id="consent_text">
    As we received no response from you, we had to remove you from the game. Please return the HIT.
    </div>
    
  );
}



