import "bootstrap/dist/css/bootstrap.min.css";
import * as Survey from 'survey-core';
import * as SurveyReact from "survey-react-ui";
import 'survey-core/modern.min.css';
import "nouislider/distribute/nouislider.css";
//@ts-ignore
import * as widgets from "surveyjs-widgets";
import { firestore_db } from "../..";
import { doc, setDoc } from "firebase/firestore";
import { demQuestions, repQuestions } from "../../state/Questions-en";
import { useEffect } from "react";
Survey.StylesManager.applyTheme("modern");

widgets.nouislider(Survey);


function getThermometer(name:string, title:string){
    
    return {
    "name": name,
    "type": "nouislider",
    "title": title,
    "isRequired": true,
    "orientation": "vertical",
    "tooltips": false,
    "direction": "rtl",
    "defaultValue":"",
    
      "pipsText": [
        {
            "value": 100,
            "text": "100 - Very warm or favorable"
          },

        {
            "value": 75,
            "text": "75 - Warm or favorable"
          },

        {
            "value": 50,
            "text": "50 - No feelings at all"
          },

        {
            "value": 25,
            "text": "25 - Cold or unfavorable"
          },
        {
          "value": 0,
          "text": "0 - Very cold or unfavorable"
        }
        
      ]
    //"pipsValues:itemvalues":["good", "good", "good", "good", "bad"],

}}



var willingrep_game = {

    "value": "willingrep_game",
    "text": "Playing this game with Republicans?",

}

var willingdem_game = {

    "value": "willingdem_game",
    "text": "Playing this game with Democrats?",

}

var willing_nonpolrep = {

    "value": "willing_nonpolrep",
    "text": "Having nonpolitical conversations with Republicans?",
}

var willing_nonpoldem = {

    "value": "willing_nonpoldem",
    "text": "Having nonpolitical conversations with Democrats?",
}

var willing_polrep = {

    "value": "willing_polrep",
    "text": "Having political conversations with Republicans?",
}

var willing_poldem = {

    "value": "willing_poldem",
    "text": "Having political conversations with Democrats?",
}

var comfort_friendrep = {
    "type": "radiogroup",
    "name": "comfort_friendrep",
    "title": "How comfortable are you having close personal friends who are Republicans?",
    "visibleIf": "{party_id} contains 'Democrat'",
    "isRequired": true,
    "colCount": 1,
    "choices": [
        "Not at all comfortable",
        "Not too comfortable",
        "Somewhat comfortable",
        "Extremely comfortable"
    ]
}

var comfort_frienddem = {
    "type": "radiogroup",
    "name": "comfort_frienddem",
    "title": "How comfortable are you having close personal friends who are Democrats?",
    "visibleIf": "{party_id} contains 'Republican'",
    "isRequired": true,
    "colCount": 1,
    "choices": [
        "Not at all comfortable",
        "Not too comfortable",
        "Somewhat comfortable",
        "Extremely comfortable"
    ]
}

var comfort_neighborrep = {
    "type": "radiogroup",
    "name": "comfort_neighborrep",
    "title": "How comfortable are you having neighbors on your street who are Republicans?",
    "visibleIf": "{party_id} contains 'Democrat'",
    "isRequired": true,
    "colCount": 1,
    "choices": [
        "Not at all comfortable",
        "Not too comfortable",
        "Somewhat comfortable",
        "Extremely comfortable"
    ]
}

var comfort_neighbordem = {
    "type": "radiogroup",
    "name": "comfort_neighbordem",
    "title": "How comfortable are you having neighbors on your street who are Democrats?",
    "visibleIf": "{party_id} contains 'Republican'",
    "isRequired": true,
    "colCount": 1,
    "choices": [
        "Not at all comfortable",
        "Not too comfortable",
        "Somewhat comfortable",
        "Extremely comfortable"
    ]
}

var upset_marryrep = {
    "type": "radiogroup",
    "name": "upset_marryrep",
    "visibleIf": "{party_id} contains 'Democrat'",
    "title": "Suppose a son or daughter of yours was getting married. How would you feel if he/she married a supporter of the Republican party?",
    "isRequired": true,
    "colCount": 1,
    "choices": [
        "Not at all upset",
        "Not too upset",
        "Somewhat upset",
        "Extremely upset"
    ]
}

var upset_marrydem = {
    "type": "radiogroup",
    "name": "upset_marrydem",
    "visibleIf": "{party_id} contains 'Republican'",
    "title": "Suppose a son or daughter of yours was getting married. How would you feel if he/she married a supporter of the Democratic party?",
    "isRequired": true,
    "colCount": 1,
    "choices": [
        "Not at all upset",
        "Not too upset",
        "Somewhat upset",
        "Extremely upset"
    ]
}



var behavior_dem = {
    "type": "matrix",
    "name": "behavior_dem",
    "visibleIf": "{party_id} contains 'Republican'",
    "isAllRowRequired": true,
    "title": "Please indicate how willing or unwilling you would be to engage in the following actions:",
    "columns": [
        {
            "value": 1,
            "text": "Very unwilling"
        }, {
            "value": 2,
            "text": "Somewhat unwilling"
        }, {
            "value": 3,
            "text": "Neither"
        }, {
            "value": 4,
            "text": "Somewhat willing"
        }
        , {
            "value": 5,
            "text": "Very willing"
        }
    ],
    "rows": [
        willingdem_game,
        willing_nonpoldem,
        willing_poldem
    ]
}

var behavior_rep = {
    "type": "matrix",
    "name": "behavior_rep",
    "visibleIf": "{party_id} contains 'Democrat'",
    "isAllRowRequired": true,
    "title": "Please indicate how willing or unwilling you would be to engage in the following actions:",
    "columns": [
        {
            "value": 1,
            "text": "Very unwilling"
        }, {
            "value": 2,
            "text": "Somewhat unwilling"
        }, {
            "value": 3,
            "text": "Neither"
        }, {
            "value": 4,
            "text": "Somewhat willing"
        }
        , {
            "value": 5,
            "text": "Very willing"
        }
    ],
    "rows": [
        willingrep_game,
        willing_nonpolrep,
        willing_polrep
    ]
}

var party_id = {
    "type": "radiogroup",
    "name": "party_id",
    "title": "Generally speaking, I think of myself as a:",
    "isRequired": true,
    "choices": [
        "Strong Democrat",
        "Weak Democrat",
        "Lean Democrat",
        "Independent",
        "Lean Republican",
        "Weak Republican",
        "Strong Republican"
    ]
}

var ideology = {
    "type": "radiogroup",
    "name": "ideology",
    "title": "We hear a lot of talk these days about liberals and conservatives. Here is a seven-point scale on which the political views that people might hold are arranged from very liberal to very conservative. Where would you place yourself on this scale?",
    "isRequired": true,
    "choices": [
        "1 - Very liberal",
        "2 - Mostly liberal",
        "3 - Somewhat liberal",
        "4 - Moderate",
        "5 - Somewhat conservative",
        "6 - Mostly conservative",
        "7 - Very conservative"
    ]
}

var manip_q2 = {
    "name": "manipulation_check_q2",
    "type": "text",
    "title": "manipulation_check1",
    "isRequired": true,
    "validators": [
        {
            "text": "only values 0-100 are allowed.",
            "type": "numeric",
            "minValue":0, 
            "maxValue":100,
        }
    ]
}

var manip_q5 = {
    "name": "manipulation_check_q5",
    "type": "text",
    "title": "manipulation_check2",
    "isRequired": true,
    "validators": [
        {
            "text": "only values 0-100 are allowed.",
            "type": "numeric",
            "minValue":0, 
            "maxValue":100,
        }
    ]
}

var game_quality = {
    "type": "matrix",
    "name": "game_quality",
    "isAllRowRequired": true,
    "title": "Please indicate if you agree or disagree with the following statements",
    "columns": [
        {
            "value": 1,
            "text": "Strongly Disagree"
        }, {
            "value": 2,
            "text": "Disagree"
        }, {
            "value": 3,
            "text": "Neither disagree nor agree"
        }, {
            "value": 4,
            "text": "Agree"
        }, {
            "value": 5,
            "text": "Strongly Agree"
        }
    ],
    "rows": [
        {
            "value": "confusing",
            "text": "Game instructions were confusing"
        },
        {
            "value": "fun",
            "text": "Game was fun to play"
        }, {
            "value": "informative",
            "text": "Game was informative"
        }, {
            "value": "difficult",
            "text": "Game was difficult"
        }, {
            "value": "surprising",
            "text": "Game answers were surprising"
        }
    ]
}

var likely_play = {
    "type": "radiogroup",
    "name": "likely_play",
    "title": "How often would you play this game if it had new questions everytime you play?",
    "isRequired": true,
    "choices": [
        "Never",
        "Rarely",
        "Occasionally",
        "A moderate amount",
        "A great deal"
    ]
}

var likely_recommend = {
    "type": "radiogroup",
    "name": "likely_recommend",
    "title": "How likely are you to recommend this game to a friend?",
    "isRequired": true,
    "choices": ["Extremely unlikely",
    "Unlikely",
    "Neutral",
    "Likely",
    "Extremely likely"]
}

var star_rating_game = {
    "type": "text",
    "isRequired": true,
    "name": "star_rating_game",
    "title": "How would you rate the game?",
    "validators": [
        {
            "text": "only values 1-10 allowed.",
            "type": "numeric",
            "minValue":1, 
            "maxValue":10,
        }]
}

var star_rating_self = {
    "type": "text",
    "isRequired": true,
    "name": "star_rating_self",
    "title": "How well did you play?",
    "validators": [
        {
            "text": "only values 1-10 allowed.",
            "type": "numeric",
            "minValue":1, 
            "maxValue":10,
        }]
}

var star_rating_partner = {
    "type": "text",
    "isRequired": true,
    "name": "star_rating_partner",
    "title": "How well did your partner play?",
    "validators": [
        {
            "text": "only values 1-10 allowed.",
            "type": "numeric",
            "minValue":1, 
            "maxValue":10,
        }]
}
var ratings = {
    "type": "panel",
    "name": "ratings",
    "title": "Overall, on a scale of 1-10, with 1 being very poor and 10 being very good:",
    "elements": [star_rating_game, star_rating_self,star_rating_partner]
}

var dem_polstereo = {
    "type": "radiogroup",
    "name": "dem_polstereo",
    "visibleIf": "{party_id} contains 'Republican'",
    "title": "How much can you tell about a person's political policy preferences by knowing that they are a Democratic party supporter?",
    "isRequired": true,
    "choices": [
            "A great deal",
            "A lot",
            "A moderate amount",
            "A little",
            "Not at all"
        ]
    
}

var dem_nonpolstereo = {
    "type": "radiogroup",
    "name": "dem_nonpolstereo",
    "visibleIf": "{party_id} contains 'Republican'",
    "title": "How much can you tell about a person's other values and goals by knowing that they are a Democratic party supporter?",
    "isRequired": true,
    "choices": [
            "A great deal",
            "A lot",
            "A moderate amount",
            "A little",
            "Not at all"
        ]
    
}


var rep_polstereo = {
    "type": "radiogroup",
    "name": "rep_polstereo",
    "visibleIf": "{party_id} contains 'Democrat'",
    "title": "How much can you tell about a person's political policy preferences by knowing that they are a Republican party supporter?",
    "isRequired": true,
    "choices": [
            "A great deal",
            "A lot",
            "A moderate amount",
            "A little",
            "Not at all"
        ]
}

var rep_nonpolstereo = {
    "type": "radiogroup",
    "name": "rep_nonpolstereo",
    "visibleIf": "{party_id} contains 'Democrat'",
    "title": "How much can you tell about a person's other values and goals by knowing that they are a Republican party supporter?",
    "isRequired": true,
    "choices": [
            "A great deal",
            "A lot",
            "A moderate amount",
            "A little",
            "Not at all"
        ]
}

var commonality = {
    "type": "matrix",
    "name": "commonality",
    "isAllRowRequired": true,
    "title": "Please indicate if you agree or disagree with the following statements",
    "columns": [
        {
            "value": 1,
            "text": "Strongly Disagree"
        }, {
            "value": 2,
            "text": "Disagree"
        }, {
            "value": 3,
            "text": "Neither agree nor disagree"
        }, {
            "value": 4,
            "text": "Agree"
        }, {
            "value": 5,
            "text": "Strongly Agree"
        }
    ],
    "rows": [
        {
            "value": "common_ground",
            "text": "There are many policy areas where Democrats and Republicans agree and can find common ground to work together.",
        }, {
            "value": "media_worse",
            "text": "Democrats and Republicans agree on many more issues than the media says that they do.",
        }
    ]
}

var reps_before_dems = Math.random() >= 0.5
var lead_before_sup = Math.random() >= 0.5

var thermos = null
if (reps_before_dems && lead_before_sup)
{
    thermos = [ getThermometer("republican_leaders", "Republican party leaders"),
                getThermometer("republican_supporters", "Republican party supporters"),
                getThermometer("democratic_leaders", "Democratic party leaders"),
                getThermometer("democratic_supporters", "Democratic party supporters")]
}
else if (!reps_before_dems && lead_before_sup)
{
    thermos = [ 
                getThermometer("democratic_leaders", "Democratic party leaders"),
                getThermometer("democratic_supporters", "Democratic party supporters"),
                getThermometer("republican_leaders", "Republican party leaders"),
                getThermometer("republican_supporters", "Republican party supporters")]
}
else if (reps_before_dems && !lead_before_sup)
{
    thermos = [ 
                getThermometer("republican_supporters", "Republican party supporters"),
                getThermometer("republican_leaders", "Republican party leaders"),
                getThermometer("democratic_supporters", "Democratic party supporters"),
                getThermometer("democratic_leaders", "Democratic party leaders")]
}
else{
    thermos = [ 
        getThermometer("democratic_supporters", "Democratic party supporters"),
        getThermometer("democratic_leaders", "Democratic party leaders"),
        getThermometer("republican_supporters", "Republican party supporters"),
        getThermometer("republican_leaders", "Republican party leaders")]
}

var reactance = {
    "type": "matrix",
    "name": "psych",
    "isAllRowRequired": true,
    "title": "Please indicate if you agree or disagree with the following statements",
    "columns": [
        {
            "value": 1,
            "text": "Strongly Disagree"
        }, {
            "value": 2,
            "text": "Disagree"
        }, {
            "value": 3,
            "text": "Neither disagree nor agree"
        }, {
            "value": 4,
            "text": "Agree"
        }, {
            "value": 5,
            "text": "Strongly Agree"
        }
    ],
    "rows": [
        {
            "value": "game_pressure",
            "text": "This game was pressuring me to think a certain way about Democrats and Republicans"
        }, {
            "value": "game_force",
            "text": "This game was forcing opinions on me about Democrats and Republicans"
        }, {
            "value": "game_manipulate",
            "text": "This game was manipulating me to form a certain view on Democrats and Republicans"
        }
    ]
}

var senate_majority = {
    "type": "radiogroup",
    "name": "senate_majority",
    "title": "Do you happen to know who is the majority leader in the U.S. Senate?",
    "isRequired": true,
    "choices": [
        "Mitch McConnell",
        "Joe Manchin",
        "Chuck Schumer",
        "Elizabeth Warren"
    ]
}

var know_house = {
    "type": "radiogroup",
    "name": "know_house",
    "title": "Do you happen to know which political party has a majority in the U.S. House of Representatives?",
    "isRequired": true,
    "choices": [
        "Republican Party",
        "Democratic Party",
        "The House is evenly divided between Democrats and Republicans"
    ]
}


var know_tie = {
    "type": "radiogroup",
    "name": "know_tie",
    "title": "In the case of a tied vote in the U.S. Senate, is the deciding vote cast by ...",
    "isRequired": true,
    "choices": [
        "The president",
        "The vice president",
        "The Senate majority leader",
        "The Senate parliamentarian",
    ]
}

var know_electoral = {
    "type": "radiogroup",
    "name": "know_electoral",
    "title": "The U.S. Electoral College ...",
    "isRequired": true,
    "choices": [
        "Supervises the presidential debates",
        "Is another name for the U.S. Congress",
        "Is an assembly that formally elects the president",
        "Trains those who run for political office",
    ]
}

var page1_elements:any = [
    party_id,
    ideology,
    likely_play,
    likely_recommend,
    ratings]


var season = {
    "type": "radiogroup",
    "name": "season",
    "title": "Based on the text you read above, which season have you been asked to enter?",
    "isRequired": true,
    "choices": [
        "Winter",
        "Spring",
        "Fall",
        "Summer"
    ]
}


var attention_check1 = {
    "type": "panel",
    "name": "attention_check1",
    "title": "You are being asked to answer a simple test. When asked to enter season you must select 'Summer'. This is an attention check.",
    "elements": [season]
}
page1_elements.push(attention_check1)

var feeling_thermos = {
    "type": "panel",
    "name": "feelings",
    "title": "We would like to get your feelings toward some groups who are in the news these days. Below, you’ll see the name of a group next to a feeling thermometer. Ratings between 50 and 100 degrees mean that you feel favorably and warm toward that group; ratings between 0 and 50 degrees mean that you don't feel favorably toward that group. You would rate the person at the 50 degree mark if you don't feel particularly warm or cold toward the group. Please use the feeling thermometer to indicate your feeling toward the following groups.",
    "elements": thermos
    
}

var socdist:any = [comfort_friendrep, comfort_frienddem, comfort_neighborrep,
    comfort_neighbordem, upset_marryrep, upset_marrydem]

var behavior_intent:any = [behavior_dem, behavior_rep]

var r1 = Math.random()
var r2 = Math.random()
if (r1 <= 0.33)
{
page1_elements.push(feeling_thermos)
if(r2 < 0.5)
{
page1_elements.push(...socdist)
page1_elements.push(...behavior_intent)
}
else{
page1_elements.push(...behavior_intent)
page1_elements.push(...socdist)
}
}
else if(r1 <= 0.66)
{
page1_elements.push(...socdist)

if(r2 < 0.5)
{
page1_elements.push(feeling_thermos)
page1_elements.push(...behavior_intent)
}
else{
page1_elements.push(...behavior_intent)
page1_elements.push(feeling_thermos)
}
}
else
{
page1_elements.push(...behavior_intent)

if(r2 < 0.5)
{
page1_elements.push(...socdist)
page1_elements.push(feeling_thermos)
}
else{
page1_elements.push(feeling_thermos)
page1_elements.push(...socdist)
}
}  


var stereos = [dem_polstereo,dem_nonpolstereo,rep_polstereo,rep_nonpolstereo]
var pol_know = [{}, {}, senate_majority, know_tie, know_house, know_electoral]



var manup_polcheck = {
    "type": "radiogroup",
    "name": "manipulation_check_political",
    "title": "How political did you find the game to be?",
    "isRequired": true,
    "choices": [
        "A great deal",
        "A lot",
        "A moderate amount",
        "A little",
        "Not at all"
    ]
}

var knowledge_qns = {
    "type": "panel",
    "name": "know",
    "title": "Please answers the following questions as best as you can. Please do not look up the answers online, you will not be penalized for providing a wrong answer.",
    "elements": pol_know
}

var freetext_box = {
    "type": "comment",
    "name": "freetext_box",
    "title": "Please use the text box below to suggest improvements and provide feedback about the game or the HIT. We will ready every comment posted here.",
    "isRequired": false
}

var page2_elements:any = []

var r3 = Math.random()
var r4 = Math.random()

if (r3 <= 0.33)
{
    page2_elements.push(reactance)

    if(r4 < 0.5)
    {
        page2_elements.push(...stereos)
        page2_elements.push(commonality)
    }
    else{
        page2_elements.push(commonality)
        page2_elements.push(...stereos)
    }

    }
    else if(r3 <= 0.66)
    {
        page2_elements.push(...stereos)

    if(r4 < 0.5)
    {
        page2_elements.push(reactance)
        page2_elements.push(commonality)
    }
    else{
        page2_elements.push(commonality)
        page2_elements.push(reactance)
    }

    }
    else
    {
        page2_elements.push(commonality)

    if(r4 < 0.5)
    {
        page2_elements.push(...stereos)
        page2_elements.push(reactance)
    }
    else{
        page2_elements.push(reactance)
        page2_elements.push(...stereos)
    }
    }


    var disagree = {
        "type": "radiogroup",
        "name": "disagree",
        "title": "Based on the text you read above, what is your level of agreement?",
        "isRequired": true,
        "choices": [
            "Strongly agree",
            "Agree",
            "Neither",
            "Disagree",
            "Strongly disagree",
        ]
    }
    
    
    var attention_check2 = {
        "type": "panel",
        "name": "attention_check2",
        "title": "Please indicate your level of agreement. When asked to select an agreement level, you must select 'Agree'. This is an attention check.",
        "elements": [disagree]
    }

page2_elements.push(...[
    manup_polcheck,
    game_quality,
    attention_check2,
    knowledge_qns,
    freetext_box
    ])

var mturk_domain = findParameter("turkSubmitTo") || ""
var mturk_url = ""
if(mturk_domain.includes("www.mturk.com")){
    mturk_url = "https://www.mturk.com" 
    
}
else{
    mturk_url = "https://workersandbox.mturk.com" 
}

var json = {
    "showProgressBar": "top",
    "requiredText":"",
    "title":"Post-Game Survey",

    "pages": [
    {"elements": page1_elements},
    {"elements": page2_elements}],
    completedHtml: "<p><h4>CLICK THE BUTTON BELOW TO SUBMIT HIT.</h4></p><form action='" + mturk_url + "/mturk/externalSubmit?assignmentId=" + findParameter("assignmentId") + "&foo=bar' method='post'><input type='submit' class='btn btn-success' name='mturk_submit' value='Submit to MTurk' /></form>"
};


function findParameter(parameterName:string) {
    var result = null,
        tmp = [];
    	window.location.search
        .slice(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}



export function SurveyPage() {
    const assignmentId = findParameter("assignmentId")
    const workerId = findParameter("workerId")
    const turkSubmitTo = findParameter("turkSubmitTo")
    const hitId = findParameter("hitId")
    var survey_start_time:number = 0

    useEffect(()=>{
        survey_start_time = Math.round(Date.now() / 1000)

    },[])

    var room_type = JSON.parse(localStorage.getItem("room_type") || "{}")
        
    if (room_type === ""){room_type = "control"}

    if(room_type === "mixed" || room_type === "full")
    {
        const q2 = localStorage.getItem("q2") || 'Enter 1 as the answer';
        const q5 = localStorage.getItem("q5") || 'Enter 2 as the answer';
        manip_q2["title"] = q2
        manip_q2["name"] = q2
        manip_q5["title"] = q5
        manip_q5["name"] = q5
        json["pages"][1]["elements"][9]["elements"][0] = manip_q2
        json["pages"][1]["elements"][9]["elements"][1] = manip_q5
    }
    else{
        const random_repq = Math.floor(Math.random() * repQuestions.length);
        const random_demq = Math.floor(Math.random() * demQuestions.length);
        const random_repq_index = Math.floor(Math.random() * repQuestions[random_repq].length);
        const random_demq_index = Math.floor(Math.random() * demQuestions[random_demq].length);

        if(Math.random() >= 0.5){
            
            manip_q2["title"] = "Give your best guess: " + repQuestions[random_repq][random_repq_index][0]
            manip_q5["title"] = "Give your best guess: " + demQuestions[random_demq][random_demq_index][0]
            manip_q2["name"] = "Give your best guess: " + repQuestions[random_repq][random_repq_index][0]
            manip_q5["name"] = "Give your best guess: " + demQuestions[random_demq][random_demq_index][0]
            json["pages"][1]["elements"][9]["elements"][0] = manip_q2
            json["pages"][1]["elements"][9]["elements"][1] = manip_q5
        }
        else{
            manip_q2["title"] = "Give your best guess: " + demQuestions[random_demq][random_demq_index][0]
            manip_q5["title"] = "Give your best guess: " + repQuestions[random_repq][random_repq_index][0]
            manip_q2["name"] = "Give your best guess: " + demQuestions[random_demq][random_demq_index][0]
            manip_q5["name"] = "Give your best guess: " + repQuestions[random_repq][random_repq_index][0]
            json["pages"][1]["elements"][9]["elements"][0] = manip_q2
            json["pages"][1]["elements"][9]["elements"][1] = manip_q5
        }
    }

    var survey_model = new Survey.Model(json);
    survey_model.completeText = "End Survey";
    survey_model.onAfterRenderPage.add(()=>{
        document.querySelectorAll(".noUi-connect").forEach(element => {
            element.setAttribute("style","transform: translate(0px, 100%) scale(1, 0.5)");
       })
    });
    survey_model.onComplete.add(async function (sender:any) {
        var survey_answers = sender.data
        survey_answers["workerId"] = workerId
        survey_answers["assignmentId"] = assignmentId
        survey_answers["turkSubmitTo"] = turkSubmitTo
        survey_answers["hitId"] = hitId
        survey_answers["complete_time"] = Math.round(Date.now() / 1000)
        survey_answers["survey_start_time"] = survey_start_time
        survey_answers["tutorial_start_time"] = JSON.parse(localStorage.getItem("tutorial_start_time") || "{}")
        survey_answers["tutorial_end_time"] = JSON.parse(localStorage.getItem("tutorial_end_time") || "{}")
        survey_answers["room_type"] = JSON.parse(localStorage.getItem("room_type") || "{}")
        survey_answers["room_id"] = JSON.parse(localStorage.getItem("room_id") || "{}")
        const roomRef = doc(firestore_db, "survey_answers", workerId + "")
        await setDoc(roomRef, survey_answers)

        });
    

  return (<div className="survey">
      <SurveyReact.Survey model={survey_model}/>
    </div>
    
  );
}