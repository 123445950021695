import { useState, useEffect } from "react";
import { GameState, InitialGameState, Team } from "../../state/GameState";
import { getDatabase, ref, onValue,set, off } from "firebase/database";
import { realtime_db } from "../../index";
export function useNetworkBackedGameState(
  room_id: string,
  playerId: string,
  playerName: string,
  room_type: string
): [GameState, (newState: Partial<GameState>) => void] {
  const [gameState, setGameState] = useState<GameState>(InitialGameState(room_type, room_id));

  useEffect(() => {
    const db = realtime_db
    
    const dbRef = ref(db, "rooms/" + room_id);

    onValue(dbRef, (appState) => {
      const networkGameState: GameState = appState.val();
      const completeGameState = {
        ...InitialGameState(room_type, room_id),
        ...networkGameState,
      };

      if (networkGameState?.roundPhase === undefined) {

        set(dbRef, completeGameState);
        return;
      }

      if (completeGameState.players[playerId] === undefined) {
        completeGameState.players[playerId] = {
          name: playerName,
          team: Team.Unset,
        };

        set(dbRef, completeGameState);
        return;
      }

      setGameState(completeGameState);
    });
    return () => off(dbRef);
  }, [playerId, playerName, room_id, room_type]);

  const dbRef = ref(getDatabase(), "rooms/" + room_id);

  return [
    gameState,
    (newState: Partial<GameState>) => {
      set(dbRef,{
        ...gameState,
        ...newState,
      });
    },
  ];
}
