import { GameState, Team } from "./GameState";
import memoize from "lodash/memoize";
import { AllCards } from "./SpectrumCards-en"; // new default for english language
import { repQuestions, demQuestions, nonpolQuestions } from "./Questions-en"; // new default for english language


const shuffleSeed: {
  shuffle: <T>(arr: T[], seed: string) => T[];
} = require("shuffle-seed");

export type Player = {
  id: string;
  name: string;
  team: Team;
};

export interface GameModel {
  gameState: GameState;
  localPlayer: Player;
  clueGiver: Player | null;
  spectrumCard: [string, string];
  QuestionCard: [string, number];
  setGameState: (newState: Partial<GameState>) => void;
}

const getSeededCardDeck = memoize((seed: string, cards: [string, string][]) =>
 { var shuff_cards = shuffleSeed.shuffle(cards.slice(2), seed)
  var ordered_cards = cards.slice(0,2)
  ordered_cards.push(...shuff_cards)
  return ordered_cards
 }
);

const getSeededQuestionDeck = memoize((seed: string, room_type: string) =>
{ 
  
  var ShuffRepQuestions = shuffleSeed.shuffle(repQuestions, seed)
  var repQs = ShuffRepQuestions.map(n => shuffleSeed.shuffle(n, seed)[0]);

  var ShuffDemQuestions = shuffleSeed.shuffle(demQuestions, seed)
  var demQs = ShuffDemQuestions.map(n => shuffleSeed.shuffle(n, seed)[0]);

  var nonpolQs = shuffleSeed.shuffle(nonpolQuestions, seed)
  
  const intval = seed.charCodeAt(0) + seed.charCodeAt(1) + seed.charCodeAt(2) + seed.charCodeAt(3)

  var polQs:any = []
  if(intval % 2 === 0)
  {
    polQs = shuffleSeed.shuffle(repQs.slice(0,4).concat(demQs.slice(0,3)), seed)
  }
  else{
    polQs = shuffleSeed.shuffle(demQs.slice(0,4).concat(repQs.slice(0,3)), seed)
  }

  if (room_type === "control"){return nonpolQs.slice(0,7)}
  else if(room_type === "full"){return polQs.slice(0,7)}
  else{
    if(intval % 2 === 0){

      return nonpolQs.slice(0,3).concat(repQs.slice(0,1), 
               nonpolQs.slice(4,6),
               demQs.slice(0,1))
    }
    else{

      return nonpolQs.slice(0,3).concat(demQs.slice(0,1), 
            nonpolQs.slice(4,6),
            repQs.slice(0,1))
    }
  }
}
  
);

export function BuildGameModel(
  gameState: GameState,
  setGameState: (newState: Partial<GameState>) => void,
  localPlayerId: string
): GameModel {
  const clueGiver = gameState.players[gameState.clueGiver]
    ? {
        ...gameState.players[gameState.clueGiver],
        id: gameState.clueGiver,
      }
    : null;

  const spectrumDeck = getSeededCardDeck(gameState.deckSeed, AllCards);
  const questionDeck = getSeededQuestionDeck(gameState.deckSeed, gameState.room_type);
  gameState.spectrumTarget = questionDeck[gameState.deckIndex % questionDeck.length][1];

  return {
    gameState,
    localPlayer: { ...gameState.players[localPlayerId], id: localPlayerId },
    clueGiver,
    spectrumCard: spectrumDeck[gameState.deckIndex % spectrumDeck.length],
    QuestionCard: questionDeck[gameState.deckIndex % questionDeck.length],
    setGameState,
  };
}
