import React, {useContext, useEffect, useState } from "react";

import { GamePhase, RoundPhase } from "../../state/GameState";
import { Spectrum } from "../common/Spectrum";
import { GameModelContext } from "../../state/GameModelContext";
import { Center, Grid, GridItem, Heading, Button, Text, useMediaQuery} from "@chakra-ui/react";
import { ForfeitModal, WarningModal, SurveyModal, PartnerCheckModal } from "../common/Modals";
import { Timer } from "../common/Timer";
import { recordPlayedOnce } from "../common/Players";

class WaitComponent extends React.Component {
  render() {
    const partner_name = JSON.parse(localStorage.getItem("partner_name")|| "{}")
    return (
      <Text textAlign={"center"} w={400} h={100} fontSize="1.5rem" fontFamily="Reem Kufi">
        {partner_name} is guessing...
        
      </Text>
    );
  }  
}




          



export function AnswerQuestion() {

	const [widthLargerThan1300] = useMediaQuery('(min-width: 1300px)');
	const [heightLargerThan600] = useMediaQuery('(min-height: 600px)');
  const is_cluegiver = JSON.parse(localStorage.getItem("clue_giver")|| "{}")
  var expiryTimestamp = new Date();

  const {
    gameState,
    localPlayer,
    clueGiver,
    QuestionCard,
    setGameState,
  } = useContext(GameModelContext);

  const [warningMsg, setWarningMsg] = useState(false);
  const [forfeitMsg, setForfeitMsg] = useState(false);
  const [clicked, setClicked] = useState(true);

  useEffect(()=>{
    
    if ( gameState.clueGiver === "" && is_cluegiver === "yes") {
      setGameState({
        clueGiver: localPlayer.id,
      });
    }

    if(gameState.turnsTaken===-1){
      recordPlayedOnce()
    }
    const start_time = Math.round(Date.now() / 1000)
    localStorage.setItem("start_time", JSON.stringify(start_time))
    
    const warnmsg_timeout_id = setTimeout(()=>{setWarningMsg(true)},61000)
    const forfeitmsg_timeout_id = setTimeout(()=>{setForfeitMsg(true)},86000)
    setClicked(false)
    return() =>{
      clearTimeout(warnmsg_timeout_id)
      clearTimeout(forfeitmsg_timeout_id)
    }

  },[])

  
  
  if(gameState.turnsTaken===2){localStorage.setItem('q2',QuestionCard[0]);}
  if(gameState.turnsTaken===5){localStorage.setItem('q5',QuestionCard[0]);}
  
  
  // if (!clueGiver) {
  //   setGameState({
  //     clueGiver: localPlayer.id,
  //   });
  //   return null;
  // }

  

  var headingSize = widthLargerThan1300 && heightLargerThan600 ? "2.0rem" : "1.5rem";
  var fontSize = widthLargerThan1300 && heightLargerThan600 ? "1.5rem" : "1.2rem";
  if (localPlayer.id !== gameState.clueGiver) {

    return (
      <Grid 
        height={{ base: '50vh', lg: '70vh' }}
        width={{ base: '80vw', lg: '60vw' }}
        templateRows="repeat(11, 1fr)"
        templateColumns="repeat(11, 1fr)"
        >
        
        {warningMsg && !forfeitMsg && !gameState.guesser_initial_guess_complete && <WarningModal/>}
        {warningMsg && !forfeitMsg && gameState.guesser_initial_guess_complete && !gameState.cluegiver_initial_guess_complete && <PartnerCheckModal/>}
        {forfeitMsg && !gameState.guesser_initial_guess_complete && <ForfeitModal/>}
        {forfeitMsg && gameState.guesser_initial_guess_complete && !gameState.cluegiver_initial_guess_complete && <SurveyModal/>}
        
        <GridItem colSpan={11} rowSpan={1}>
          <Heading mb={2} pl={15} pr={15} w="full" fontSize={headingSize} fontFamily="Reem Kufi" fontWeight="normal">
            {QuestionCard[0]}
          </Heading>
          {(gameState.gamePhase === GamePhase.Trial) &&<Text pl={15} pr={15} textColor="#858585" fontSize={fontSize} fontFamily="Reem Kufi">
            Move the slider to your best guess!
          </Text>}
        </GridItem>
        <GridItem colSpan={11} rowStart={4}>
        <Spectrum
            spectrumCard={["0%", "100%"]}
            handleValue={gameState.guesser_initial_guess}
            roundPhase={gameState.roundPhase}
            onClick={()=>{setClicked(true)}}
            onChangeEnd={(guesser_initial_guess: number) => {
              setGameState({
                guesser_initial_guess
              });
            }}
          />

        </GridItem>
        <GridItem colStart={6} rowStart={7}>
          <Center>
            <Button
              size={widthLargerThan1300 && heightLargerThan600 ? 'lg' : 'sm'}
              fontSize={widthLargerThan1300 && heightLargerThan600 ? '1.5rem' : '1.3rem'}
              bg="#65C863"
              isDisabled={clicked===false}
              onClick={() => {

                setGameState({
                  guesser_initial_guess_complete: true
                });

                if (gameState.cluegiver_initial_guess_complete) {

                  setGameState({
                    roundPhase: RoundPhase.GiveClue,
                  });
                }

              }}
            >
              Submit Guess
            </Button>
          </Center>
        </GridItem>
        <GridItem colSpan={11} rowSpan={2} rowStart={8}>
        <Center>
          {((gameState.guesser_initial_guess_complete === true) && (gameState.cluegiver_initial_guess_complete === false)) ? <WaitComponent /> : null}
          {(gameState.guesser_initial_guess_complete === false) ? <Text textAlign={"center"} fontSize={fontSize} fontFamily="Reem Kufi" color="black"> <Timer expiryTimestamp={expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60)} /></Text>: null}
        </Center>
        </GridItem>
      </Grid>
    );
  }

  return (
    <Grid
      height={{ base: '50vh', lg: '70vh' }}
      width={{ base: '80vw', lg: '60vw' }}
      templateRows= "repeat(11, 1fr)"
      templateColumns= "repeat(11, 1fr)"
    >

    {forfeitMsg && !gameState.cluegiver_initial_guess_complete && <ForfeitModal/>}
    {forfeitMsg && gameState.cluegiver_initial_guess_complete && !gameState.guesser_initial_guess_complete && <SurveyModal/>}
    {warningMsg && !forfeitMsg && gameState.cluegiver_initial_guess_complete && !gameState.guesser_initial_guess_complete && <PartnerCheckModal/>}    
    {warningMsg && !forfeitMsg && !gameState.cluegiver_initial_guess_complete && <WarningModal/>}

      <GridItem colSpan={11} rowSpan={1}>
        <Heading mb={2} pl={15} pr={15} w="full" fontSize={headingSize} fontFamily="Reem Kufi" fontWeight="normal">
          {QuestionCard[0]}
        </Heading>
        {(gameState.gamePhase === GamePhase.Trial) &&<Text pl={15} pr={15} textColor="#858585" fontSize={fontSize} fontFamily="Reem Kufi">
          Move the slider to your best guess!
        </Text>}
      </GridItem>

      <GridItem colSpan={11} rowStart={4}>
        <Spectrum
          spectrumCard={["0%", "100%"]}
          handleValue={gameState.cluegiver_initial_guess}
          roundPhase={gameState.roundPhase}
          onClick={()=>{setClicked(true)}}
          onChangeEnd={(cluegiver_initial_guess: number) => {
            setGameState({
              cluegiver_initial_guess,
            });
          }}
        />
      </GridItem>
      <GridItem colStart={6} rowStart={7}>
        <Center>
          <Button
            size={widthLargerThan1300 && heightLargerThan600 ? 'lg' : 'sm'}
            fontSize={widthLargerThan1300 && heightLargerThan600 ? '1.5rem' : '1.3rem'}
            bg="#65C863"
            isDisabled={clicked===false}
            onClick={() => {

              setGameState({
                cluegiver_initial_guess_complete: true,
              });


              if(gameState.guesser_initial_guess_complete){
              setGameState({
                roundPhase: RoundPhase.GiveClue,
              });}


            }}
          >Submit Guess</Button>
        </Center>
      </GridItem>
      <GridItem colSpan={11} rowSpan={2} rowStart={8}>
        <Center>
          {((gameState.cluegiver_initial_guess_complete === true) && (gameState.guesser_initial_guess_complete === false)) ? <WaitComponent /> : null}
          {(gameState.cluegiver_initial_guess_complete === false) ? <Text textAlign={"center"} fontSize={fontSize} fontFamily="Reem Kufi" color="black"> <Timer expiryTimestamp={expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60)} /></Text>: null}
        </Center>
      </GridItem>
    </Grid>
               
      );
  }
  
