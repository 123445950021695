import { Center, Button, Text, ModalFooter, ModalHeader } from "@chakra-ui/react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react"
import { useHistory } from "react-router";
import { RoundPhase } from "../../state/GameState";
import { useContext, useState } from "react";
import { GameModelContext } from "../../state/GameModelContext";
import { firestore_db } from "../..";
import { doc, setDoc } from "firebase/firestore";


export function NudgeModal() {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={20}>
          <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
            <Center>Alert!</Center>
          </ModalHeader>
                    <ModalBody>
                        <Text fontFamily="Reem Kufi" fontSize="1.5rem">Please input your guess now!</Text>
                    </ModalBody>
                    <ModalFooter>
                      <Button colorScheme='green' mr={3} onClick={onClose} disabled={false}>
                        OK
                      </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
  }

export function WarningModal() {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={20}>
          <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
            <Center>Alert!</Center>
          </ModalHeader>
                    <ModalBody>
                        <Text fontFamily="Reem Kufi" fontSize="1.5rem">Still playing? Input your guess now!</Text>
                    </ModalBody>
                    <ModalFooter>
                      <Button colorScheme='green' mr={3} onClick={onClose} disabled={false}>
                        OK
                      </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
  }

export function PassModal() {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={20}>
          <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
            <Center>Alert!</Center>
          </ModalHeader>
                    <ModalBody>
                        <Text fontFamily="Reem Kufi" fontSize="1.5rem">Time's up! Click the purple PASS button to continue. Click PASS asap, so we know that you haven't left the game.</Text>
                    </ModalBody>
                    <ModalFooter>
                      <Button colorScheme='green' mr={3} onClick={onClose} disabled={false}>
                        OK
                      </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
  }

export function PartnerCheckModal() {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const partner_name = JSON.parse(localStorage.getItem("partner_name")|| "{}")
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={20}>
          <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
            <Center>Alert!</Center>
          </ModalHeader>
                    <ModalBody>
                        <Text fontFamily="Reem Kufi" fontSize="1.5rem">Hang tight while we check on {partner_name}.</Text>
                    </ModalBody>
                    <ModalFooter>
                      <Button colorScheme='green' mr={3} onClick={onClose} disabled={false}>
                        OK
                      </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
  }



export function ForfeitModal() {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const history = useHistory();
    const parameters = window.location.search
    const setEnd = () => {
      
      history.push("/end" + parameters)
    }


  
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent borderRadius={20}>
          <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
            <Center>Alert!</Center>
          </ModalHeader>
                    <ModalBody>
                        <Text fontFamily="Reem Kufi" fontSize="1.5rem">We didn't receive an input from you. We have removed you from the game.</Text>
                    </ModalBody>
                    <ModalFooter>
                    <Button colorScheme='green' mr={3} onClick={setEnd} disabled={false}>
                        OK
                      </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
  }

export function SurveyModal() {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const partner_name = JSON.parse(localStorage.getItem("partner_name")|| "{}")
  
    const history = useHistory();
    const parameters = window.location.search
  
    const setSurvey = () => {
      
      history.push("/postgamesurvey" + parameters)
    }
  
  
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent borderRadius={20}>
          <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
            <Center>Alert!</Center>
          </ModalHeader>
                    <ModalBody>
                        <Text fontFamily="Reem Kufi" fontSize="1.5rem">Oops! Looks like {partner_name} is not responding or has left the game.</Text>
                        {(parameters !== "") &&<Text fontFamily="Reem Kufi" fontSize="1.5rem">Please proceed to the game survey page. You can still complete the rest of the HIT and get paid.</Text>}
                    </ModalBody>
                    <ModalFooter>
                      {(parameters !== "") && <Button colorScheme='green' mr={3} onClick={setSurvey} disabled={false}>
                        Game Survey
                      </Button>}
                    </ModalFooter>
                </ModalContent>
                
            </Modal>
        </>
    )
  }

  export function PartnerStartGameModal() {

    const {
      setGameState,
    } = useContext(GameModelContext);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const partner_name = JSON.parse(localStorage.getItem("partner_name")|| "{}")
  
    const history = useHistory();
  
    const setTrial = () => {
      setGameState({
        roundPhase: RoundPhase.AnswerQuestion,
      });}
      
    
  
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent borderRadius={20}>
          <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
            <Center>Alert!</Center>
          </ModalHeader>
                    <ModalBody>
                        <Text fontFamily="Reem Kufi" fontSize="1.5rem">{partner_name} is ready to play. Let's start the trial!</Text>
                        
                    </ModalBody>
                    <ModalFooter>
                    <Button colorScheme='green' mr={3} onClick={setTrial} disabled={false}>
                        Start trial
                      </Button>
                    </ModalFooter>
                </ModalContent>
                
            </Modal>
        </>
    )
  }

export function StartGameModal() {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const partner_name = JSON.parse(localStorage.getItem("partner_name")|| "{}")
    const [ waitingmsg, setWaiting ] = useState(false)
    
    const {
      gameState,
      localPlayer,
      clueGiver,
      setGameState,
    } = useContext(GameModelContext);
    
    const setTrial = () => {

      if((clueGiver === null) || (localPlayer.id === clueGiver.id)){
        setGameState({
          cluegiver_trial_ready: true
        });

        if (gameState.guesser_trial_ready) {
          setGameState({
            roundPhase: RoundPhase.AnswerQuestion,
          });
        }
        else{
          
          setWaiting(true)
          
        }
      }

      else{
        setGameState({
          guesser_trial_ready: true
        });

        if (gameState.cluegiver_trial_ready) {
          setGameState({
            roundPhase: RoundPhase.AnswerQuestion,
          });
        }
        else{
          setWaiting(true)
        }
      }
    }
      
    
  
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent borderRadius={20}>
          <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
            <Center>Alert!</Center>
          </ModalHeader>
                    <ModalBody>
                        <Text fontFamily="Reem Kufi" fontSize="1.5rem">Let's start the game!</Text>
                        
                    </ModalBody>
                    <ModalFooter>
                    <Button colorScheme='green' mr={3} onClick={setTrial} disabled={waitingmsg == true}>
                        Start trial
                      </Button>
                      {waitingmsg && <Text fontFamily="Reem Kufi" fontSize="1.5rem">Waiting for {partner_name}...</Text>}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
  }


export function RedirectMatchingModal() {
      const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
      const partner_name = JSON.parse(localStorage.getItem("partner_name")|| "{}")
      const history = useHistory();
      const parameters = window.location.search
    
      const redirectMatch = () => {
  
          history.push("/matching" + parameters)
        }

      return (
          <>
              <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
                  <ModalOverlay />
                  <ModalContent borderRadius={20}>
            <ModalHeader fontSize="2.0rem" fontFamily="Reem Kufi">
              <Center>Alert!</Center>
            </ModalHeader>
                      <ModalBody>
                          <Text fontFamily="Reem Kufi" fontSize="1.5rem">Unfortunately, {partner_name} is not responding. Please click 'Match' to be matched with another player.</Text>
                        
                      </ModalBody>
                      <ModalFooter>
                      <Button colorScheme='green' mr={3} onClick={redirectMatch} disabled={false}>
                          Match
                        </Button>
                        
                      </ModalFooter>
                  </ModalContent>
              </Modal>
          </>
      )
    }