import "./index.css";
import "tippy.js/dist/tippy.css";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/compat/app";


import App from "./components/App";

import { ChakraProvider } from '@chakra-ui/react'
import { getFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import { getDatabase, connectDatabaseEmulator} from "firebase/database";


// firebaseApps previously initialized using initializeApp()
firebase.initializeApp({ projectId: "guessync" });

export const realtime_db = getDatabase();
export const firestore_db = getFirestore();



//export const analytics = getAnalytics();

// Point to the RTDB emulator running on localhost.
if (document.location.hostname === "localhost") {

  connectDatabaseEmulator(realtime_db, "localhost", 9000);
  connectFirestoreEmulator(firestore_db, 'localhost', 8080);
}

//firebase.initializeApp(firebaseConfig);
//firebase.analytics().logEvent("screen_view", {
//  app_name: "GuesSync",
//  screen_name: "index",
//});


ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
    <ChakraProvider>
      <App />
    </ChakraProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

//ReactDOM.render(
//  <React.StrictMode>
//            <App/>
//  </React.StrictMode>,
//  document.getElementById("root")
//);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



