import "bootstrap/dist/css/bootstrap.min.css";
import {
  Text,
  Container,
  Flex,
  Heading,
} from "@chakra-ui/react";

export function AboutPage() {
  return(
    <Flex align="center" w="100vw" h="100vh">
      <Container
        overflowY="hidden"
        pt={10}
        pb={10}
        bg="#EFEEEE"
        borderRadius="20px"
        display='flex'
        margin={{ base: '10px', sm: 'auto' }}
        centerContent
      >
        <Heading fontFamily="Reem Kufi">
          About The Game
        </Heading>
        <Text fontSize="1.2rem" fontFamily="Reem Kufi">
          <b>Developed by:</b>
          <br />
          <br />
          Ashwin Rajadesingan
          <br />
          Jessica Zhang
          <br />
          Mia Inakage
          <br />
          Daniel Choo
          <br/>
          <br/>
          from the University of Michigan
        </Text>
      </Container>
    </Flex>
  );
}  