import { RandomSpectrumTarget } from "./RandomSpectrumTarget";
import { RandomFourCharacterString } from "./RandomFourCharacterString";

export enum RoundPhase {
  PickTeams,
  MatchDetails,
  Tutorial,
  AnswerQuestion,
  GiveClue,
  MakeGuess,
  CounterGuess,
  ViewScore,
}

export enum GameType {
  Teams,
  Cooperative,
  Freeplay,
}

export enum GamePhase {
  Trial,
  Full
}
export enum Team {
  Unset = 0,
  Left = 1,
  Right = 2,
}

export function TeamReverse(team: Team) {
  if (team === Team.Left) {
    return Team.Right;
  }
  if (team === Team.Right) {
    return Team.Left;
  }
  return Team.Unset;
}


export type PlayersTeams = {
  [playerId: string]: {
    name: string;
    team: Team;
  };
};

export type TurnSummaryModel = {
  spectrumCard: [string, string];
  clueGiverName: string;
  clue: string;
  spectrumTarget: number;
  guess: number;
};

export interface GameState {
  gameType: GameType;
  roundPhase: RoundPhase;
  turnsTaken: number;
  deckSeed: string;
  room_type: string;
  deckIndex: number;
  spectrumTarget: number;
  clue: string;
  guess: number;
  counterGuess: "left" | "right";
  players: PlayersTeams;
  clueGiver: string;
  leftScore: number;
  rightScore: number;
  freePlayScore: number;
  coopScore: number;
  coopBonusTurns: number;
  previousTurn: TurnSummaryModel | null;
  cluegiver_initial_guess: number;
  guesser_initial_guess: number;
  cluegiver_initial_guess_complete: boolean;
  guesser_initial_guess_complete: boolean;
  guesser_trial_ready: boolean;
  cluegiver_trial_ready: boolean;
  guesser_game_ready: boolean;
  cluegiver_game_ready: boolean;
  cluegiver_next_round_ready: boolean;
  guesser_next_round_ready: boolean;
  room_id: string;
  gamePhase: GamePhase;
}

export function InitialGameState(room_type:string, room_id:string): GameState {
  return {
    gameType: GameType.Freeplay,
    roundPhase: RoundPhase.MatchDetails,
    turnsTaken: -1,
    deckSeed: RandomFourCharacterString(),
    room_type: room_type,
    deckIndex: 0,
    spectrumTarget: RandomSpectrumTarget(),//100,
    clue: "",
    guess: 50,
    counterGuess: "left",
    players: {},
    clueGiver: "",
    leftScore: 0,
    rightScore: 0,
    freePlayScore: 0,
    coopScore: 0,
    coopBonusTurns: 0,
    previousTurn: null,
    cluegiver_initial_guess: 50,
    guesser_initial_guess: 50,
    cluegiver_initial_guess_complete: false,
    guesser_initial_guess_complete: false,
    guesser_trial_ready: false,
    cluegiver_trial_ready: false,
    guesser_game_ready: false,
    cluegiver_game_ready: false,
    cluegiver_next_round_ready: false,
    guesser_next_round_ready: false,
    room_id: room_id,
    gamePhase: GamePhase.Trial

  };
}
