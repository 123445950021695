import { useParams } from "react-router-dom";
import { useStorageBackedState } from "../hooks/useStorageBackedState";
import { useNetworkBackedGameState } from "../hooks/useNetworkBackedGameState";
import { InputName } from "./InputName";
import { GameModelContext } from "../../state/GameModelContext";
import { RoundPhase } from "../../state/GameState";
import { ActiveGame } from "./ActiveGame";
import { BuildGameModel } from "../../state/BuildGameModel";
import { Container, Flex, VStack } from '@chakra-ui/react'
import { GameHeader } from "../common/Header";


export function GameRoom() {
  


  const [playerName, setPlayerName] = useStorageBackedState("", "name");  
  const playerId = JSON.parse(localStorage.getItem('player_id') || "{}");
  var room_type = JSON.parse(localStorage.getItem('room_type') || "{}");
  var room_id = JSON.parse(localStorage.getItem('room_id') || "{}");

  
  const [gameState, setGameState] = useNetworkBackedGameState(
    room_id,
    playerId,
    playerName,
    room_type
  );

  if (room_type === ""||room_id === ""){return (<div id="consent_text">
  Oops! We encountered an error. Please return the HIT and message us.
  </div>)}

  const gameModel = BuildGameModel(gameState, setGameState, playerId);



  if (!gameState?.players?.[playerId]) {
    return null;
  }


  const avatar_phases = [RoundPhase.AnswerQuestion, RoundPhase.GiveClue, RoundPhase.MakeGuess, RoundPhase.ViewScore]
  

  var include_header = false
  if(avatar_phases.includes(gameState.roundPhase))
  {
    include_header = true
  }


  return (
    <GameModelContext.Provider value={gameModel}>
      <Flex
        align="center"
        w="100vw"
        h="100vh"
      >
        <Container
          display='flex'
          centerContent
        >
          <VStack>
            {include_header && <GameHeader
              username={playerName}
              player_role={gameState.clueGiver === playerId ? "Clue Giver" : "Guesser"} />}
            <ActiveGame />
          </VStack>
        </Container>
      </Flex>
    </GameModelContext.Provider>
  );
}
