
import { Heading, useMediaQuery, } from "@chakra-ui/react";
import "@fontsource/reem-kufi";

export function GameTitle() {
  const [heightLargerThan600] = useMediaQuery('(min-height: 500px)');
  const normTitle = (
    <Heading fontFamily="Reem Kufi" fontSize={{ base: '4rem', sm: '6rem' }}>
      GuesSync!
    </Heading>
  );
  const heightTitle = (
    <Heading fontFamily="Reem Kufi" fontSize="3.0rem">
      GuesSync!
    </Heading>
  );
  return (
    heightLargerThan600 ? normTitle : heightTitle
  )
}
