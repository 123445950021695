import "../../index.css";
import React, { useRef, useEffect, useState, useContext } from "react";
import { Message, StreamChat } from "stream-chat";
import {
  Chat,
  Channel,
  MessageList,
  MessageInput,
  Thread,
  Window
} from "stream-chat-react";
import {
  Button,
  Box,
  Text,
} from "@chakra-ui/react";
import {
  ChatAutoComplete,
  EmojiIconLarge,
  EmojiPicker,
  Tooltip,
  useMessageInputContext,
  useTranslationContext,
 } from 'stream-chat-react';
import { DefaultAttachmentType, DefaultMessageType, DefaultUserType } from "stream-chat-react/dist/types/types";
import {
  Avatar,
  messageHasReactions,
  MessageOptions,
  MessageRepliesCountButton,
  MessageText,
  ReactionSelector,
  SimpleReactionsList,
  useMessageContext,
} from 'stream-chat-react';
import { Flex, VStack } from "@chakra-ui/layout";
import { GameModelContext } from "../../state/GameModelContext";
import { RoundPhase } from "../../state/GameState";

export const avatar_img_url_dict: { [key: string]: any } = {

  "0":"https://i.ibb.co/jvNdQNg/5.png",

  "1":"https://i.ibb.co/j55yJ7p/test.png",
  
  "2":"https://i.ibb.co/1r6wg35/1.png",
  
  "3":"https://i.ibb.co/Cn9Bz6W/2.png",
  
  "4":"https://i.ibb.co/tKCgGKN/3.png",
  
  "5":"https://i.ibb.co/kh7bmFt/4.png"};




const customStyles = {
  '--primary-color': 'green',
  '--md-font': '1.2rem',
  '--xs-m': '1.2rem',
  '--xs-p': '1.2rem',

};





 export type SendButtonProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Me extends DefaultMessageType = DefaultMessageType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  sendMessage: (
    event: React.BaseSyntheticEvent,
    customMessageData?: Partial<Message<At, Me, Us>>,
  ) => void
};






export const CustomMessage = () => {
  const {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
  } = useMessageContext();





  const messageWrapperRef = useRef(null);

  const hasReactions = messageHasReactions(message);


  return (
    <div className='message-wrapper'>
      
      
      <div className='message-wrapper-content'>
      <Flex>
        <VStack id='user_details' spacing='1px'>
          
      <Avatar image={message.user?.image} />
        <MessageOptions displayLeft={false} messageWrapperRef={messageWrapperRef} />
        <div className='message-header'>
          <div className='message-header-name'>{message.user?.name}</div>
          {/*<div className='message-header-timestamp'><MessageTimestamp /> </div>*/}
        </div>
       
        </VStack>
        <VStack>
        {showDetailedReactions && isReactionEnabled && (
          <ReactionSelector ref={reactionSelectorRef} />
        )}
        <MessageText />
        {hasReactions && !showDetailedReactions && isReactionEnabled && <SimpleReactionsList />}
        <MessageRepliesCountButton reply_count={message.reply_count} />
      
      </VStack>
      
      </Flex>
      </div>
    </div>
    
  );
};

export const NewSendButton = <
    At extends DefaultAttachmentType = DefaultAttachmentType,
    Me extends DefaultMessageType = DefaultMessageType,
    Us extends DefaultUserType<Us> = DefaultUserType
  >({
    sendMessage,
  }: SendButtonProps<At, Me, Us>) => {
    
    const { t } = useTranslationContext('SendButton');
    return (
      <button className='str-chat__send-button' onClick={ sendMessage }>
        <svg height='17' viewBox='0 0 18 17' width='18' xmlns='http://www.w3.org/2000/svg'>
          <title>{t('Send')}</title>
          <path
            d='M0 17.015l17.333-8.508L0 0v6.617l12.417 1.89L0 10.397z'
            fill='#01452c'
            fillRule='evenodd'
          />
        </svg>
      </button>
    );
  };






export function GameChat() {

  const chat_token = JSON.parse(localStorage.getItem("chat_token")||"{}")
  const player_id = JSON.parse(localStorage.getItem("player_id")||"{}")
  const player_name = JSON.parse(localStorage.getItem("name")||"{}")
  const room_id = JSON.parse(localStorage.getItem("room_id")||"{}")
  const avatar = JSON.parse(localStorage.getItem("avatar")||"{}")
  const partner_name = JSON.parse(localStorage.getItem("partner_name")||"{}")
  
  const [chatClient, setChatClient] = useState<any | null>(null);

  const [channel, setChannel] = useState<any | null>(null);
  const { gameState } = useContext(GameModelContext);
  useEffect(() => {
    if (player_id === "" || player_name === "" || room_id === "") return;

    const client = StreamChat.getInstance('yzhp2tnffu79');

    const setupClient = async () => {
      try {
        await client.connectUser({
          id: player_id,
          name: player_name,
          image: avatar_img_url_dict[avatar]
        },
        chat_token,
        );
        setChatClient(client);
        
        const chat_channel = await client.channel('messaging', room_id, { name: 'Game Chat' });
        await chat_channel.create();
        if (!chat_channel.state.members[player_id]) await chat_channel.addMembers([player_id]);
        setChannel(chat_channel);
      } catch (err) { 
        console.log("error:" +err); 
      }
    };
    setupClient();

    return () => {
      client.disconnectUser();}

    
  }, []);

  if (channel == null) return null;
  
    
  const CustomMessageInput = () => {
    const { t } = useTranslationContext();
    const {
      closeEmojiPicker,
      emojiPickerIsOpen,
      handleEmojiKeyDown,
      handleSubmit,
      openEmojiPicker,
    } = useMessageInputContext();

    const [showButtons, setShowButtons] = useState(true)

    const suggest_button = (message:string) => { return <Button className="suggestion_button" onClick={async() => {await channel.sendMessage({text: message}); setShowButtons(false);}}>{message}</Button>}
    const close_button = <Button className="suggestionbox_close_button" onClick={() => {return setShowButtons(false);}}>x</Button>
    const suggestion_box = (box1:string, box2:string) => { return <div><div className="suggestion_box">{suggest_button(box1)} {suggest_button(box2)} </div> {close_button}</div>}
    
    var display_buttons = null

    if(gameState.turnsTaken !== 5)
    {
      if(gameState.roundPhase === RoundPhase.ViewScore && gameState.clueGiver === player_id)
      {
          if(Math.abs(gameState.spectrumTarget - gameState.guess) <= 2)
          {
            display_buttons = suggestion_box("AMAZING", "YESSS!")
          }
          else if(Math.abs(gameState.spectrumTarget - gameState.guess) <= 5)
          {
            display_buttons = suggestion_box("Awesome!", "Great job!") 
          }
          else if(Math.abs(gameState.spectrumTarget - gameState.guess) <= 10)
          {
            display_buttons = suggestion_box("Phew..", "Pretty decent!")
          }
          else{
            display_buttons = suggestion_box("OOPS", "We tried!")
          }
        
      }
      else if(gameState.roundPhase === RoundPhase.ViewScore && gameState.clueGiver !== player_id)
      {
        if(Math.abs(gameState.spectrumTarget - gameState.guess) <= 2)
          {
            display_buttons = suggestion_box("YAYYY", "WOW!")
          }
          else if(Math.abs(gameState.spectrumTarget - gameState.guess) <= 5)
          {
            display_buttons = suggestion_box("Great clue", "Nice!")
          }
          else if(Math.abs(gameState.spectrumTarget - gameState.guess) <= 10)
          {
            display_buttons = suggestion_box("Tough", "Not bad!")
          }
          else{
            display_buttons = suggestion_box("WHOA!", "Surprising!")
          }
      }
      else if(gameState.roundPhase === RoundPhase.MatchDetails)
      {
        if(gameState.clueGiver === player_id){
          display_buttons = suggestion_box("Hi!", "Let's start!")
          }
          else{
          display_buttons = suggestion_box("Hey!", "Let's begin!")
          }
      }
    }
    else{
      if(gameState.clueGiver === player_id){
        display_buttons = suggestion_box("You did great!", "Ciya!")
        }
        else{
        display_buttons = suggestion_box("Fun playing with ya!", "Bye!")
        }
    }
    
    
    return (

      <div
        className='str-chat__input-flat str-chat__input-flat--send-button-active'>
        <div >
          {showButtons ? display_buttons : null}
        </div>
        <div className='str-chat__input-flat-wrapper'>
          <div className='str-chat__input-flat--textarea-wrapper'>
            <ChatAutoComplete />
            <div className='str-chat__emojiselect-wrapper'>
              <Tooltip>
                {emojiPickerIsOpen ? t('Close emoji picker') : t('Open emoji picker')}
              </Tooltip>
              <span
                className='str-chat__input-flat-emojiselect'
                onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
                onKeyDown={handleEmojiKeyDown}
                role='button'
                tabIndex={0}
              >
                <EmojiIconLarge />
              </span>
            </div>
            <EmojiPicker />
          </div>
          <NewSendButton sendMessage={handleSubmit} />
        </div>
      </div>

    );
   };


  return (
    <div>
      {(gameState.roundPhase === RoundPhase.MatchDetails ||
        gameState.roundPhase === RoundPhase.ViewScore) &&
        <Chat client={chatClient} customStyles={customStyles}>
          <Channel channel={channel} Input={CustomMessageInput} Message={CustomMessage}>
            <Window>
              <MessageList disableDateSeparator={true} messageActions={['react']} />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      }
      {(gameState.roundPhase === RoundPhase.AnswerQuestion ||
        gameState.roundPhase === RoundPhase.MakeGuess ||
        gameState.roundPhase === RoundPhase.GiveClue) &&

        <Box
          backgroundColor="#EFEEEE"
          borderRadius={20}
          height={{ base: "40vh", lg: "70vh" }}
          width={{ base: "80vw", lg: "30vw" }}
        > 
          <Text pt={5} textColor="#858585" fontSize="1.5rem" fontFamily="Reem Kufi" style={{ textAlign: 'center' }}>
          You can chat with {partner_name} only
        </Text>
          <Text textColor="#858585" fontSize="1.5rem" fontFamily="Reem Kufi" style={{ textAlign: 'center' }}>
            at the end of each round.
          </Text>
        </Box>

      }
    </div>
  );
}
