import { GamePhase, RoundPhase} from "../../state/GameState";
import { GiveClue } from "./GiveClue";
import { MakeGuess } from "./MakeGuess";
import { ViewScore} from "./ViewScore";
import { AnswerQuestion } from "./AnswerQuestion";
import { MatchDetails } from "./MatchDetails";
import { Tutorial } from "./Tutorial";
import { useContext } from "react";
import { GameModelContext } from "../../state/GameModelContext";
import { GameChat } from "./Chat";
import {
  Text,
  Box,
  Stack,
  Center,
  useMediaQuery,
} from "@chakra-ui/react";


export function ActiveGame() {
	const [widthLargerThan1300] = useMediaQuery('(min-width: 1300px)');
	const [heightLargerThan600] = useMediaQuery('(min-height: 600px)');

  const { gameState} = useContext(GameModelContext);
  var round_text = null
  if(gameState.gamePhase === GamePhase.Trial){
    round_text = "Trial round " + (gameState.turnsTaken + 2) + " of 2"
  }
  else{
    round_text = "Round " + (gameState.turnsTaken) + " of 5"
  }
  return (
    <Stack direction={{base: 'column', lg: 'row'}} spacing={5}>
      <Box
        height={{base: '50vh', lg: '70vh'}}
        width={{base: '80vw', lg: '60vw'}}
        borderRadius="20px"
        bg="#EFEEEE"
        pt={3}
      >
        <Center>
          <Text
            textColor="#858585"
            fontSize={widthLargerThan1300 && heightLargerThan600 ? "2.0rem" : "1.5rem"}
            fontFamily="Reem Kufi"
          >
            {(gameState.roundPhase === RoundPhase.AnswerQuestion ||
              gameState.roundPhase === RoundPhase.GiveClue ||
              gameState.roundPhase === RoundPhase.MakeGuess ||
              gameState.roundPhase === RoundPhase.ViewScore) && round_text}
          </Text>
        </Center>
        {gameState.roundPhase === RoundPhase.MatchDetails && <MatchDetails />}
        {gameState.roundPhase === RoundPhase.Tutorial && <Tutorial />}
        {gameState.roundPhase === RoundPhase.AnswerQuestion && <AnswerQuestion />}
        {gameState.roundPhase === RoundPhase.GiveClue && <GiveClue />}
        {gameState.roundPhase === RoundPhase.MakeGuess && <MakeGuess />}
        {gameState.roundPhase === RoundPhase.ViewScore && <ViewScore />}

      </Box>
      <GameChat />
      
    </Stack>
  );
}
