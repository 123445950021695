import React, { useEffect, useState } from "react";
import { useStorageBackedState } from "../hooks/useStorageBackedState";
import { useContext } from "react";
import { GameModelContext } from "../../state/GameModelContext";
import { RoundPhase } from "../../state/GameState";
import { firestore_db } from "../../index";
import {doc, getDoc} from 'firebase/firestore';
import { avatar_dict } from "../common/LandingPage";
import avatar from 'animal-avatar-generator';
import {
  Heading,
  Text,
  Container,
  Button,
  HStack,
  VStack,
  Box,
  Center,
  useMediaQuery,
} from "@chakra-ui/react";
import { ForfeitModal, PartnerStartGameModal, RedirectMatchingModal, StartGameModal } from "../common/Modals";

class WaitComponent extends React.Component {
  render() {
    const partner_name = JSON.parse(localStorage.getItem("partner_name")|| "{}")
    return (
      <Text textAlign={"center"} w={500} h={100} fontSize="1.5rem" fontFamily="Reem Kufi">
        Waiting for {partner_name} to start the trial...
      </Text>
    );
  }
}

export function MatchDetails() {
  const {
    gameState,
    localPlayer,
    setGameState,
  } = useContext(GameModelContext);

  const [widthLargerThan1000] = useMediaQuery('(min-width: 1000px)')
  const [heightLargerThan800] = useMediaQuery('(min-height: 800px)')
  

  const partner_id = JSON.parse(localStorage.getItem("partner_id") || "{}")
  // const pet = JSON.parse(localStorage.getItem("pet") || "{}")
  // const place = JSON.parse(localStorage.getItem("place") || "{}")
  const player_avatar = JSON.parse(localStorage.getItem("avatar") || "{}")

  // const [partner_pet, set_partner_pet] = useStorageBackedState("", "partner_pet");
  // const [partner_place, set_partner_place] = useStorageBackedState("", "partner_place");
  const [partner_name, set_partner_name] = useStorageBackedState("", "partner_name");
  const [partner_avatar, set_partner_avatar] = useStorageBackedState("", "partner_avatar");
  const [startMsg, setStartMsg] = useState(false);
  const [leaveGame, setLeaveGame] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const is_cluegiver = JSON.parse(localStorage.getItem("clue_giver")|| "{}")
  
  let partner_avatar_svg = "";
  let avatarSize = widthLargerThan1000 ? 150 : 100;
  avatarSize = heightLargerThan800 ? avatarSize : 100;

  if (["0", "1", "2", "3", "4", "5"].includes(partner_avatar)) {
    partner_avatar_svg = avatar(avatar_dict[partner_avatar], { size: avatarSize, 'round': true, 'blackout': false })
  }

  let player_avatar_svg = ""
  if (["0", "1", "2", "3", "4", "5"].includes(player_avatar)) {
    player_avatar_svg = avatar(avatar_dict[player_avatar], { size: avatarSize, 'round': true, 'blackout': false })
  }

  // const partner_id = localStorage.getItem('partner_id') || '';
  // const pet = localStorage.getItem('pet')|| '';
  // const place = localStorage.getItem('place') || '';

  useEffect(() => {

    if ( is_cluegiver === "yes") {
      setGameState({
        clueGiver: localPlayer.id,
      });
    }

    async function getPartnerDetails() {
      
      const userRef = doc(firestore_db, "users", partner_id);
      const docSnap = await getDoc(userRef);
      
      if (docSnap.exists()) {
        const user_data = docSnap.data()
        // set_partner_pet(user_data.pet)
        // set_partner_place(user_data.place)
        set_partner_name(user_data.player_name)
        set_partner_avatar(user_data.avatar)
        // localStorage.setItem('partner_pet',user_data.pet);
        // localStorage.setItem('partner_place',user_data.place);
        // localStorage.setItem('partner_name',user_data.player_name);
      }
    }
    getPartnerDetails();
    

  
    const leavegame_timeout_id = setTimeout(()=>{setLeaveGame(true)},50000)
    const startmsg_timeout_id = setTimeout(()=>{setStartMsg(true)},30000)
    return() =>{
      clearTimeout(leavegame_timeout_id)
      clearTimeout(startmsg_timeout_id)
    }

  }, [])

  // const partner_pet = localStorage.getItem('partner_pet')|| '';
  // const partner_place = localStorage.getItem('partner_place') || '';
  // const partner_name = localStorage.getItem('partner_name') || '';


  // if (docSnap.exists()) {
  // 
  // } else {
  // // doc.data() will be undefined in this case
  // 
  // }

  // TODO: A bit ad-hoc but temporary solution- fix later?
  // let petCommon: string = (pet === partner_pet) ? pet : "";
  // let placeCommon: string = (place === partner_place) ? place : "";
  // let userPet = "";
  // let userPlace = "";
  // let partnerPet = "";
  // let partnerPlace = "";

  // if (petCommon === "") {
  //   userPet = pet;
  //   partnerPet = partner_pet;
  // }
  // if (placeCommon === "") {
  //   userPlace = place;
  //   partnerPlace = partner_place;
  // }

  let fontSize = widthLargerThan1000 ? "2.0rem" : "1.5rem";
  fontSize = heightLargerThan800 ? fontSize : "1.5rem";

  if (is_cluegiver === "yes") {

    return (
      <VStack spacing={heightLargerThan800 ? "2.5vh" : "0.5vh"}>
         {(startMsg && !leaveGame && (gameState.cluegiver_trial_ready === false) && (gameState.guesser_trial_ready === true)) ? <PartnerStartGameModal /> : null}
         {(startMsg && !leaveGame && (gameState.cluegiver_trial_ready === false) && (gameState.guesser_trial_ready === false)) ? <StartGameModal /> : null}
         {leaveGame && (gameState.cluegiver_trial_ready === false) && <ForfeitModal/>}
         {leaveGame && (gameState.cluegiver_trial_ready === true) && <RedirectMatchingModal/>}
        <Heading fontFamily="Reem Kufi" fontSize={fontSize}>
          You and {partner_name} matched!
        </Heading>
        <HStack>
          <Container centerContent>
            <span dangerouslySetInnerHTML={{ __html: player_avatar_svg }} />
            <Text fontFamily="Reem Kufi" fontSize={fontSize}>
              You
            </Text>
          </Container>

          <Container centerContent>
            <span dangerouslySetInnerHTML={{ __html: partner_avatar_svg }} />
            <Text fontFamily="Reem Kufi" fontSize={fontSize}>
              {partner_name}
            </Text>
          </Container>
        </HStack>
        <Text textAlign={"center"} fontFamily="Reem Kufi" fontSize={fontSize}>Use the chat to say hello and start the game!</Text>
        
        <Text textAlign={"center"} fontFamily="Reem Kufi" fontSize={fontSize}>The first two rounds are practice rounds.</Text>

        <Button
          size={widthLargerThan1000 && heightLargerThan800 ? 'lg' : 'sm'}
          disabled={disabled}
          onClick={() => {
            if (disabled) {
              return;
            }
            setDisabled(true);
            setGameState({
              cluegiver_trial_ready: true
            });

            if (gameState.guesser_trial_ready) {
              setGameState({
                roundPhase: RoundPhase.AnswerQuestion,
              });
            }
          }}
        >
          Start Trial
        </Button>
        {((gameState.cluegiver_trial_ready === true) && (gameState.guesser_trial_ready === false)) ? <WaitComponent /> : null}
      </VStack>
    );
  }

  return (
      <VStack spacing={heightLargerThan800 ? "2.5vh" : "0.5vh"}>
      {(startMsg && (gameState.cluegiver_trial_ready === true) && (gameState.guesser_trial_ready === false)) ? <PartnerStartGameModal /> : null}
      {(startMsg && (gameState.cluegiver_trial_ready === false) && (gameState.guesser_trial_ready === false)) ? <StartGameModal /> : null}
      {leaveGame && (gameState.guesser_trial_ready === false) && <ForfeitModal/>}
      {leaveGame && (gameState.guesser_trial_ready === true) && <RedirectMatchingModal/>} 
      <Heading fontFamily="Reem Kufi" fontSize={fontSize}>
          You and {partner_name} matched!
        </Heading>
        <HStack>
          <Container centerContent>
            <span dangerouslySetInnerHTML={{ __html: player_avatar_svg }} />
            <Text fontFamily="Reem Kufi" fontSize={fontSize}>
              You
            </Text>
          </Container>

          <Container centerContent>
            <span dangerouslySetInnerHTML={{ __html: partner_avatar_svg }} />
            <Text fontFamily="Reem Kufi" fontSize={fontSize}>
              {partner_name}
            </Text>

          </Container>
        </HStack>
        
          <Text textAlign={"center"} fontFamily="Reem Kufi" fontSize={fontSize}>Use the chat to say hello and start the game!</Text>
    
          <Text textAlign={"center"} fontFamily="Reem Kufi" fontSize={fontSize}>The first two rounds are practice rounds.</Text>
          <Button
            size={widthLargerThan1000 && heightLargerThan800 ? 'lg' : 'sm'}
            disabled={disabled}
            onClick={() => {
              if (disabled) {
                return;
              }
              setDisabled(true);
              setGameState({
                guesser_trial_ready: true
              });

              if (gameState.cluegiver_trial_ready) {
                setGameState({
                  roundPhase: RoundPhase.AnswerQuestion,
                });
              }
            }}

          >
            Start Trial
          </Button>
          
          {((gameState.guesser_trial_ready === true) && (gameState.cluegiver_trial_ready === false)) ? <WaitComponent /> : null}
       
      </VStack>
  );


}
