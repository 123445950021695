import "stream-chat-css/dist/css/index.css";
import "../App.css";

import "@fontsource/reem-kufi";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GameRoom } from "./gameplay/GameRoom";
import { LandingPage } from "./common/LandingPage";
import { ConsentPage } from "./common/ConsentPage";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { MatchQuestions } from "./gameplay/MatchQuestions";
import { Button } from './styles/ButtonStyle';
import { SurveyPage } from "./common/SurveyPage";
import { Tutorial } from "./gameplay/Tutorial";
import { AboutPage } from "./common/AboutPage";
import { EndPage } from "./common/EndPage";

// Version 1: Using objects
const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: '#65C863',
        color: 'black',
      },
      // styles for the `a`
    },
  },
  components: {
    Button,
  },
})


function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
          <Switch>
            <Route path="/rooms/:roomId">
              <GameRoom />
            </Route>
            <Route path="/about">
              <AboutPage/>
            </Route>
            <Route path="/tutorial">
              <Tutorial />
            </Route>
            <Route path="/matching">
              <MatchQuestions />
            </Route>
            <Route path="/consent">
              <ConsentPage />
            </Route>
            <Route path="/end">
              <EndPage />
            </Route>
            <Route path="/postgamesurvey">
              <SurveyPage />
            </Route>
            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        </BrowserRouter>
      </ChakraProvider>
  );
}

export default App;
