import { useContext, useEffect, useState } from "react";
import { GamePhase, GameType, RoundPhase} from "../../state/GameState";
import { Spectrum } from "../common/Spectrum";
import { GameModelContext } from "../../state/GameModelContext";
import { ScoreCoopRound, ScoreFreePlayRound } from "../../state/ScoreRound";
import { firestore_db } from "../../index";

import {
  Grid,
  GridItem,
  Heading,
  Text,
  Button,
  Center,
  CircularProgress,
  useMediaQuery,
} from "@chakra-ui/react";
import { doc, setDoc } from "firebase/firestore";
import { ForfeitModal, WarningModal, PartnerCheckModal, SurveyModal } from "../common/Modals";
import { Timer } from "../common/Timer";

export function MakeGuess() {
	const [widthLargerThan1300] = useMediaQuery('(min-width: 1300px)');
	const [heightLargerThan600] = useMediaQuery('(min-height: 600px)');
  var expiryTimestamp = new Date();

  const {
    gameState,
    localPlayer,
    clueGiver,
    spectrumCard,
    QuestionCard,
    setGameState,
  } = useContext(GameModelContext);
  const [clicked, setClicked] = useState(true);
  const [forfeitMsg, setForfeitMsg] = useState(false);
  const [warnMsg, setWarnMsg] = useState(false);

  const partner_name = JSON.parse(localStorage.getItem("partner_name") || "{}");

  useEffect(()=>{

    const guess_start_time = Math.round(Date.now() / 1000)
    localStorage.setItem("guess_start_time", JSON.stringify(guess_start_time))

    const warnmsg_timeout_id = setTimeout(()=>{setWarnMsg(true)},61000)
    const forfeitmsg_timeout_id = setTimeout(()=>{setForfeitMsg(true)},76000)
    setClicked(false)
    return() =>{
      clearTimeout(warnmsg_timeout_id)
      clearTimeout(forfeitmsg_timeout_id)
    }
    
  },[])

  if (!clueGiver) {
    return null;
  }

  var headingSize = widthLargerThan1300 && heightLargerThan600 ? "2.0rem" : "1.5rem";
  var fontSize = widthLargerThan1300 && heightLargerThan600 ? "1.5rem" : "1.2rem";

  if (localPlayer.id === clueGiver.id) {

    return (
      <Grid
        height={{ base: '50vh', lg: '70vh' }}
        width={{ base: '80vw', lg: '60vw' }}
        templateRows="repeat(11, 1fr)"
        templateColumns="repeat(11, 1fr)"
      >
        {forfeitMsg && <SurveyModal/>}
        {warnMsg && !forfeitMsg && <PartnerCheckModal/>}
        
        <GridItem colSpan={11} rowStart={1}>
          <Heading mb={2} pl={15} pr={15} w="full" fontSize={headingSize} fontFamily="Reem Kufi" fontWeight="normal">
            {QuestionCard[0]}
          </Heading>
          {(gameState.gamePhase === GamePhase.Trial) &&<Text pl={15} pr={15} textColor="#858585" fontSize={fontSize} fontFamily="Reem Kufi">
            Let's hope {partner_name} guesses it right!
          </Text>}
        </GridItem>
        <GridItem colSpan={11} rowStart={2}>
          <Center>
            <Text fontSize={fontSize} fontFamily="Reem Kufi">
              <b>Your clue</b>: {gameState.clue}
            </Text>
          </Center>
        </GridItem>
        <GridItem colSpan={11} rowStart={4}>
          <Spectrum
            spectrumCard={spectrumCard}
            guessingValue={gameState.guess}
            initialGuessValue={gameState.cluegiver_initial_guess}
            clueGiverGuessValue={gameState.guesser_initial_guess}
            targetValue={QuestionCard[1]}
            roundPhase={gameState.roundPhase}
          />
        </GridItem>
        <GridItem colSpan={11} rowStart={7}>
          <Center>
            <Text fontSize={fontSize} fontFamily="Reem Kufi" fontWeight="normal">
              {partner_name} is guessing...
            </Text>
          </Center>
        </GridItem>
        <GridItem colSpan={11} rowStart={8}>
          <Center>
            <Text textAlign={"center"} fontSize={fontSize} fontFamily="Reem Kufi" color="black"> <Timer expiryTimestamp={expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60)} /></Text>
          </Center>
        </GridItem>
        <GridItem colSpan={11} rowStart={9}>
          <Center>
            <CircularProgress size="3vw" isIndeterminate color='purple.300' />
          </Center>
          
        </GridItem>
      </Grid>
    );
  }

  return (
    <Grid
      height={{ base: '50vh', lg: '70vh' }}
      width={{ base: '80vw', lg: '60vw' }}
      templateRows="repeat(11, 1fr)"
      templateColumns="repeat(11, 1fr)"
    >
      {forfeitMsg && <ForfeitModal/>}
      {warnMsg && !forfeitMsg && <WarningModal/>}

      <GridItem colSpan={11} rowSpan={1}>
        <Heading mb={2} pl={15} pr={15} w="full" fontSize={headingSize} fontFamily="Reem Kufi" fontWeight="normal">
          {QuestionCard[0]}
        </Heading>
        {(gameState.gamePhase === GamePhase.Trial) &&<Text pl={15} pr={15} textColor="#858585" fontSize={fontSize} fontFamily="Reem Kufi">
        Tip: Use the clue, question and initial guesses to make the final guess.
        </Text>}
        {/* <Text pl={31} textColor="#858585" fontSize="1.5rem" fontFamily="Reem Kufi">
          Pro Tip: Before submitting, check if your final guess is a plausible answer to the question.
        </Text> */}
      </GridItem>
      <GridItem colSpan={11} rowStart={2}>
        <Center>
          <Text fontSize={fontSize} fontFamily="Reem Kufi">
            <b>{clueGiver.name}'s clue</b>: {gameState.clue}
          </Text>
        </Center>
      </GridItem>
      <GridItem colSpan={11} rowStart={4}>
        <Spectrum
          spectrumCard={spectrumCard}
          handleValue={gameState.guess}
          initialGuessValue={gameState.guesser_initial_guess}
          clueGiverGuessValue={gameState.cluegiver_initial_guess}
          clueGiverName={clueGiver.name}
          roundPhase={gameState.roundPhase}
          localPlayerName={localPlayer.name}
          onClick={()=>{setClicked(true)}}
          onChangeEnd={(guess: number) => {
            setGameState({
              guess,
            });
          }}
        />
      </GridItem>
      <GridItem colStart={6} rowStart={7}>
        <Center>
          <Button
            fontSize={widthLargerThan1300 && heightLargerThan600 ? '1.5rem' : '1.3rem'}
            size={widthLargerThan1300 && heightLargerThan600 ? 'lg' : 'sm'}
            bg="#65C863"
            isDisabled={clicked==false}
            onClick={async() => {
              const current_time = Math.round(Date.now() / 1000)
              const start_time = JSON.parse(localStorage.getItem("start_time") || "{}")
              const clue_start_time = JSON.parse(localStorage.getItem("clue_start_time") || "{}")
              const guess_start_time = JSON.parse(localStorage.getItem("guess_start_time") || "{}")
              

              const roomRef = doc(firestore_db, "answers", gameState.room_id)
              await setDoc(doc(roomRef,  "rounds", gameState.turnsTaken+1 + ""), {
                question:QuestionCard[0],
                answer:QuestionCard[1],
                cluegiver_initial_guess:gameState.cluegiver_initial_guess,
                guesser_initial_guess:gameState.guesser_initial_guess,
                final_guess:gameState.guess,
                turn_number:gameState.turnsTaken,
                room_id:gameState.room_id,
                cluegiver:gameState.clueGiver,
                clue:gameState.clue,
                spectrum:spectrumCard,
                round_starttime: start_time,
                round_endtime: current_time,
                clue_starttime: clue_start_time,
                guess_starttime: guess_start_time,
                game_phase: gameState.gamePhase
              })
              
                if (gameState.gameType === GameType.Teams) {
                  setGameState({
                    roundPhase: RoundPhase.CounterGuess,
                  });
                } else if (gameState.gameType === GameType.Cooperative) {
                  setGameState(ScoreCoopRound(gameState));
                } else {
                  setGameState(ScoreFreePlayRound(gameState));
                }
              }
            }
          >
            Submit Guess
          </Button>
        </Center>
      </GridItem>
      <GridItem colSpan={11} rowSpan={1} rowStart={8}>
        <Center>
          {<Text textAlign={"center"} fontSize={fontSize} fontFamily="Reem Kufi" color="black"> <Timer expiryTimestamp={expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60)} /></Text>}
        </Center>
      </GridItem>
    </Grid>
  );
}
