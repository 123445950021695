import { useContext, useEffect, useState } from "react";
import { GetScore } from "../../state/GetScore";
import { Spectrum } from "../common/Spectrum";
import {
  GameType,
  GamePhase,
} from "../../state/GameState";
import { GameModelContext } from "../../state/GameModelContext";
import { NewRound } from "../../state/NewRound";
import { ViewQuestions } from "./ViewQuestions";

import {
  Grid,
  GridItem,
  Heading,
  Text,
  Box,
  Button,
  Center,
  useMediaQuery,
} from "@chakra-ui/react";

import { BsFillCaretRightFill } from "react-icons/bs";
import { firestore_db } from "../../index";
import { collection, doc, getDocs } from "firebase/firestore";

// class WaitComponent extends React.Component {
//   render() {
//     const partner_name = JSON.parse(localStorage.getItem("partner_name")|| "")
//     const { gameState, localPlayer, clueGiver, setGameState } = useContext(
//       GameModelContext
//     );

//     if (clueGiver === null){return}
//     if(localPlayer.id === clueGiver.id){
//     return (
//       <div>
//      {((gameState.cluegiver_next_round_ready === true) && (gameState.guesser_next_round_ready === false)) && <Text w={200} h={100} fontSize="1.5rem" fontFamily="Reem Kufi">
//         Waiting for {partner_name} to be ready...
//       </Text>}
//       </div>

//     );}
//     else{
//       <div>
//      {((gameState.cluegiver_next_round_ready === false) && (gameState.guesser_next_round_ready === true)) && <Text w={200} h={100} fontSize="1.5rem" fontFamily="Reem Kufi">
//         Waiting for {partner_name} to be ready...
//       </Text>}
//       </div>

//     }
//   }  
// }


export function ViewScore() {
	const [widthLargerThan1300] = useMediaQuery('(min-width: 1300px)');
	const [heightLargerThan600] = useMediaQuery('(min-height: 600px)');

  const { gameState, clueGiver, QuestionCard, localPlayer,setGameState} = useContext(GameModelContext);
  const partner_name = JSON.parse(localStorage.getItem("partner_name")|| "{}")
  const [questions, setQuestionData] = useState([] as any);
  const [open, setOpen] = useState(false);

  function nextRound(){
    if ((clueGiver != null) && (localPlayer.id === clueGiver.id)) {
      const partner_id = JSON.parse(localStorage.getItem("partner_id")|| "{}")
      setGameState(NewRound(partner_id, gameState));
    }
  }

  // Grab question data
  useEffect(() => {

    if(gameState.gameType === GameType.Freeplay && gameState.turnsTaken < 5)
    {
      const nextround_timeout_id = setTimeout(()=>{nextRound()},60000)
      return() =>{
        clearTimeout(nextround_timeout_id)
      }
    }
    
    const get_rounds = async () => {
      const roomRef = doc(firestore_db, "answers", gameState.room_id);
      const querySnapshot = await getDocs(collection(roomRef, "rounds"));

      for (var i in querySnapshot.docs) {

        const round_data = querySnapshot.docs[i].data();

        if(round_data.game_phase === GamePhase.Full){
            const question: string = round_data.question;
            const cluegiver: number = round_data.cluegiver_initial_guess; // cluegiver
            const guesser: number = round_data.guesser_initial_guess; // guesser
            const final: number = round_data.final_guess; // final
            const ans: number = round_data.answer;
            const points: number = GetScore(ans, final);
            setQuestionData((questions: any[]) => [...questions, {
              question: question,
              clue_g: cluegiver,
              guess_g: guesser,
              final_g: final,
              answer: ans,
              points: points,
            }]);
        }

      }
    }
    if(gameState.gameType === GameType.Freeplay && gameState.turnsTaken >= 5)
    {
      get_rounds();
      const opensummary_timeout_id = setTimeout(()=>{setOpen(true)},60000)
      return() =>{
        clearTimeout(opensummary_timeout_id)
      }
    }
  }, [])

  if (!clueGiver) {
    return null;
  }
  var score = GetScore(gameState.spectrumTarget, gameState.guess);

  var waitComponent = null
  if(localPlayer.id === clueGiver.id){
    waitComponent =
      <div>
        {((gameState.cluegiver_next_round_ready === true) && (gameState.guesser_next_round_ready === false)) && <Text textAlign={"center"} w={400} h={100} fontSize="1.5rem" fontFamily="Reem Kufi">
          Waiting for {partner_name} to be ready...
        </Text>}
      </div>
  }
  else {
    waitComponent =
      <div>
        {((gameState.cluegiver_next_round_ready === false) && (gameState.guesser_next_round_ready === true)) && <Text textAlign={"center"} w={400} h={100} fontSize="1.5rem" fontFamily="Reem Kufi">
          Waiting for {partner_name} to be ready...
        </Text>}
      </div>
  }

  var gameComplete = null
  var gameOver: boolean = false;
  if (gameState.gameType === GameType.Freeplay && gameState.turnsTaken >= 5) {
    gameOver = true;
    gameComplete = <>
      <Button
        mr={10}
        onClick={() => {
          setOpen(true);
        }}
      >
        Game Summary
      </Button>
    </>
  }

  var score_color = "green"
  if (score === 2) { score_color = 'orange' }
  else if (score === 0) { score_color = 'red' }

  var headingSize = widthLargerThan1300 && heightLargerThan600 ? "2.0rem" : "1.5rem";
  var fontSize = widthLargerThan1300 && heightLargerThan600 ? "1.5rem" : "1.2rem";

  return (
    <Grid
      height={{ base: '50vh', lg: '70vh' }}
      width={{ base: '80vw', lg: '60vw' }}
      templateRows="repeat(11, 1fr)"
      templateColumns="repeat(11, 1fr)"
    >
      <ViewQuestions questions={questions} isOpen={open} final_score={gameState.freePlayScore}/>
      <GridItem colSpan={11} rowStart={1}>
        <Heading mb={2} pl={15} pr={15} w="full" fontSize={headingSize} fontFamily="Reem Kufi" fontWeight="normal">
          {QuestionCard[0]}
        </Heading>
      </GridItem>
      <GridItem colSpan={11} rowStart={2}>
        <Center>
          <Text fontSize={fontSize} fontFamily="Reem Kufi">
            <b>Correct Answer</b>: {gameState.spectrumTarget}%
            <br/>
            <b>Final Guess</b>: {gameState.guess}%
          </Text>
        </Center>
      </GridItem>
      <GridItem colSpan={11} rowStart={4}>
        <Center>
          
            <Text color={score_color} fontSize={headingSize} fontFamily="Reem Kufi">
              {score} points!
            </Text>
        </Center>
      </GridItem>
      <GridItem colSpan={11} rowStart={5}>
        <Spectrum
          spectrumCard={["0%", "100%"]}
          targetValue={gameState.spectrumTarget}
          roundPhase={gameState.roundPhase}
          initialGuessValue={gameState.guesser_initial_guess}
          clueGiverGuessValue={gameState.cluegiver_initial_guess}
          finalGuess={gameState.guess}
        />
      </GridItem>

      <GridItem colSpan={11} rowStart={6}>
        <Center>
          {gameOver ?
            <Text fontSize={headingSize} fontFamily="Reem Kufi" textAlign={"center"}>
              <b>Game over!</b>
              <br />
              <b>Final score: {gameState.freePlayScore} points</b>
            </Text>
            :null}
        </Center>
      </GridItem>

      <GridItem colStart={11} rowStart={8} >
        <NextTurnOrEndGame />
        <Center>{gameComplete}</Center>
      </GridItem>
      <GridItem colSpan={11} rowSpan={3} rowStart={9}>
        <Center>{waitComponent}</Center>
      </GridItem>
    </Grid>
  );
}

export function NextTurnOrEndGame() {
	const [widthLargerThan1300] = useMediaQuery('(min-width: 1300px)');
	const [heightLargerThan600] = useMediaQuery('(min-height: 600px)');

  const [disableSubmit, setDisableSubmit] = useState(false);
  const { gameState, localPlayer, clueGiver, setGameState } = useContext(
    GameModelContext
  );
  if (!clueGiver) {
    return null;
  }

  if (
    gameState.gameType === GameType.Freeplay &&
    gameState.turnsTaken >= 5
  ){
    return null;
  }

  const partner_id = JSON.parse(localStorage.getItem("partner_id")|| "{}")

  var next_round_text = (gameState.gamePhase === GamePhase.Trial && gameState.turnsTaken === 0)? "Start Game":"Next Round"

  if (localPlayer.id === clueGiver.id) {
    return (
      <Box>
          <Button
            fontSize={widthLargerThan1300 && heightLargerThan600 ? '1.5rem' : '1.1rem'}
            size={widthLargerThan1300 && heightLargerThan600 ? 'lg' : 'sm'}
            mr={10}
            rightIcon={<BsFillCaretRightFill size="40px" color="#65C863" />}
            onClick={() => {
              setGameState({
                cluegiver_next_round_ready: true
              });
              setDisableSubmit(true);
              if (gameState.guesser_next_round_ready) {
                setGameState(NewRound(partner_id, gameState));
              }
            }}>
            {next_round_text}
          </Button>
        {/* <GridItem colSpan={11} rowStart={10}>
{((gameState.cluegiver_next_round_ready === true) && (gameState.guesser_next_round_ready === false)) ? <WaitComponent /> : null}
</GridItem> */}
      </Box>
    );
  }
  else {
    return (
      <Box>
          <Button
            fontSize={widthLargerThan1300 && heightLargerThan600 ? '1.5rem' : '1.1rem'}
            size={widthLargerThan1300 && heightLargerThan600 ? 'lg' : 'sm'}
            mr={10}
            rightIcon={<BsFillCaretRightFill size="40px" color="#65C863" />}
            onClick={() => {
              setGameState({
                guesser_next_round_ready: true
              });
              setDisableSubmit(true);
              if (gameState.cluegiver_next_round_ready) {
                setGameState(NewRound(localPlayer.id, gameState));
              }
            }}>
            {next_round_text}
          </Button>

        {/* <GridItem colSpan={11} rowStart={10}>
{((gameState.guesser_next_round_ready === true) && (gameState.cluegiver_next_round_ready === false)) ? <WaitComponent /> : null}
</GridItem> */}
      </Box>
    );
  }
}
