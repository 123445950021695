import React, { Fragment} from "react";
import { RoundPhase } from "../../state/GameState";

import { BiBullseye } from 'react-icons/bi';

import { GiTargeted } from 'react-icons/gi';

import {
  Container,
  Text,
  Slider,
  SliderTrack,
  SliderMark,
  SliderThumb,
  Box,
} from "@chakra-ui/react"

function InitalGuessMark(props: {
  initialGuess?: number;
  roundPhase?:number;
}) {

  if (props.initialGuess !== undefined) {
    return (
      <Fragment>
        {props.roundPhase !== RoundPhase.MakeGuess && props.roundPhase !== RoundPhase.ViewScore && <SliderMark
          value={props.initialGuess}
          textAlign="center"
          bg="clear"
          color="black"
          m="35px 0px 0px -150px"
          w="300px"
        >
          <Text fontFamily="Reem Kufi" fontSize="1.3rem" color="purple.500">
            You: {props.initialGuess}%
          </Text>
        </SliderMark>}
        <SliderMark
          value={props.initialGuess}
        >
          <SliderThumb width={30} height={30} bgColor="transparent" >
            {(props.roundPhase === RoundPhase.ViewScore || props.roundPhase === RoundPhase.GiveClue || props.roundPhase === RoundPhase.MakeGuess) && <Box color='purple' opacity={0.3} width={30} height={30} as={GiTargeted} />}
            {(props.roundPhase !== RoundPhase.ViewScore && props.roundPhase !== RoundPhase.GiveClue && props.roundPhase !== RoundPhase.MakeGuess) && <Box color='purple' width={30} height={30} as={GiTargeted} />}
          </SliderThumb>
        </SliderMark>
      </Fragment>
    )
  }
  return null;
}

function ClueGiverGuessMark(props: {
  clueGiverName?: string;
  clueGiverGuess?: number;
  roundPhase?:number;
}) {
  if (props.clueGiverGuess !== undefined) {
    return (
      <Fragment>
        {props.roundPhase !== RoundPhase.MakeGuess && props.roundPhase !== RoundPhase.ViewScore && <SliderMark
          value={props.clueGiverGuess}
          textAlign="center"
          bg="clear"
          color="black"
          m="-75px 0px 0px -150px"
          w="300px"
        >
          <Text fontFamily="Reem Kufi" fontSize="1.3rem" color="pink.500">
            {props.clueGiverName}: {props.clueGiverGuess}%
          </Text>
        </SliderMark>}
        <SliderMark
          value={props.clueGiverGuess}
        >
          <SliderThumb width={30} height={30} bgColor="transparent" >
            {(props.roundPhase === RoundPhase.ViewScore || props.roundPhase === RoundPhase.GiveClue || props.roundPhase === RoundPhase.MakeGuess) && <Box color='#9a188a' opacity={0.3} width={30} height={30} as={GiTargeted} />}
            {(props.roundPhase !== RoundPhase.ViewScore && props.roundPhase !== RoundPhase.GiveClue && props.roundPhase !== RoundPhase.MakeGuess) && <Box color='#9a188a' width={30} height={30} as={GiTargeted} />}
          </SliderThumb>
        </SliderMark>
      </Fragment>
    )
  }
  return null;
}

function FinalGuessMark(props: {
  finalGuess?: number;
  roundPhase?: number;
}) {
  if (props.finalGuess !== undefined) {
    return (
      <Fragment>
        {props.roundPhase !== RoundPhase.ViewScore && <SliderMark
          value={props.finalGuess}
          textAlign="center"
          bg="clear"
          color="black"
          m="-70px 0px 0px -150px"
          w="300px"
        >
          <Text fontFamily="Reem Kufi" fontSize="1.5rem" color="pink.500">
            Final guess: {props.finalGuess}%
          </Text>
        </SliderMark>}
        <SliderMark
          value={props.finalGuess}
        >
          <SliderThumb width={30} height={30} bgColor="transparent">
            <Box color='black' width={30} height={30} as={GiTargeted} />
          </SliderThumb>
        </SliderMark>
      </Fragment>
    )
  }
  return null;
}
function TargetMark(props: {
  targetValue?: number;
  roundPhase?: number;
}) {
  if (props.targetValue !== undefined) {
    return (
      <Fragment>
        {props.roundPhase !== RoundPhase.ViewScore && <SliderMark
          value={props.targetValue}
          textAlign="center"
          bg="clear"
          color="black"
          m="10px 0px 0px -70px"
          w="150px"
        >
          <Text fontFamily="Reem Kufi" fontSize="1.5rem" color="red.500">
            Target: {props.targetValue}%
          </Text>
        </SliderMark>}
        <SliderMark
          value={props.targetValue}
        >
          <SliderThumb width={30} height={30} bgColor="red.500">
            <Box color='white' width={30} height={30} as={BiBullseye} />
          </SliderThumb>
        </SliderMark>
      </Fragment>
    )
  }
  return null;
}
export function Spectrum(props: {
  spectrumCard: [string, string];
  roundPhase?: number;
  handleValue?: number;
  targetValue?: number;
  guessingValue?: number;
  initialGuessValue?: number;
  clueGiverGuessValue?: number;
  finalGuess?: number;
  clueGiverName?: string;
  localPlayerName?: string;
  onChangeEnd?: (newValue: number) => void;
  onClick?: () => void;
}) {

  let handleStyle: React.CSSProperties = {
    height: 18,
    width: 18,
    backgroundColor: "rgba(255,255,255,0.8)",
    borderColor: "black",
  };


  if (!props.onChangeEnd) {
    handleStyle.cursor = "auto";
    handleStyle.boxShadow = "none";
  }

  if (props.handleValue === undefined) {
    handleStyle.display = "none";
  }

  let marks: {
    [n: number]: { style: React.CSSProperties; label: string };
  } = {};

  if (props.targetValue !== undefined) {
    marks[props.targetValue] = {
      style: {  color: "red", cursor: "auto" },
      label: "Target\n(" + props.targetValue + "%)"//t("{props.targetValue/0.2}"),//
    }
  }
  
  if (props.guessingValue !== undefined) {
    marks[props.guessingValue] = {
      style: { color: "black", cursor: "auto" },
      label: "Final (" + props.guessingValue + "%)"
    };
  }

  if ((props.handleValue !== undefined) && (props.roundPhase === RoundPhase.AnswerQuestion)) {
    marks[props.handleValue] = {
      style: { color: "purple", cursor: "auto" },
      label: "Guess (" + props.handleValue + "%)"
    };
  }

  if ((props.handleValue !== undefined) && ((props.roundPhase === RoundPhase.MakeGuess) || (props.roundPhase === RoundPhase.ViewScore))) {
    marks[props.handleValue] = {
      style: { color: "black", cursor: "auto" },
      label: "Final Guess (" + props.handleValue + "%)"
    };
  }
  
  return (
    <Container h="full" w="full" centerContent>
        {(props.handleValue) &&
          <Text fontSize="1.5rem" fontFamily="Reem Kufi" style={marks[props.handleValue].style}>
            {marks[props.handleValue].label}
          </Text>
        }
      <Slider
        aria-label="slider-ex-1"
        defaultValue={50}
        onChangeEnd={props.onChangeEnd}
        onClick={props.onClick}
        minWidth="50vw"

      >
        <SliderTrack
          borderRadius={20} // Removed value cause it kept casuing errors?
          bgGradient="linear(to-r, purple.200 10%, purple.300 25%, #00ffd1)"
          height={4}
          width="full"
        />
        <SliderMark
          value={3}
          textAlign="center"
          bg="clear"
          color="black"
          m="-50px 0px 0px -150px"
          w="300px"
        >
          <Text fontFamily="Reem Kufi" fontSize="1.5rem">
            {props.spectrumCard[0]}
          </Text>
        </SliderMark>
        <SliderMark
          value={97}
          textAlign="center"
          bg="clear"
          color="black"
          m="-50px 0px 0px -150px"
          w="300px"
        >
          <Text fontFamily="Reem Kufi" fontSize="1.5rem">
            {props.spectrumCard[1]}
          </Text>
        </SliderMark>
        <TargetMark targetValue={props.targetValue} roundPhase={props.roundPhase} />
        <InitalGuessMark initialGuess={props.initialGuessValue} roundPhase={props.roundPhase} />
        <ClueGiverGuessMark clueGiverName={props.clueGiverName} clueGiverGuess={props.clueGiverGuessValue} roundPhase={props.roundPhase} />
        <FinalGuessMark finalGuess={props.finalGuess} roundPhase={props.roundPhase} />
        {(
          (props.roundPhase === RoundPhase.MakeGuess && props.clueGiverName !== props.localPlayerName))
          && <SliderThumb width={30} height={30} bgColor="transparent">
            <Box color='black' width={30} height={30} as={GiTargeted} />
          </SliderThumb>}
        {(props.roundPhase === RoundPhase.AnswerQuestion)
          && <SliderThumb width={30} height={30} bgColor="transparent">
            <Box color='purple' width={30} height={30} as={GiTargeted} />
          </SliderThumb>}
      </Slider>
    </Container>
  );
}
